import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.png';
import { getAuth, signOut } from 'firebase/auth';
import { GrLogout } from "react-icons/gr";
import { MdOutlineRefresh } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const Header = ({ userVerificacao }) => {
  const [usuarioLogado] = useState(userVerificacao);
  const hoje = new Date();
  const dia = hoje.getDate();
  const mes = hoje.getMonth() + 1; // Janeiro é 0
  const ano = hoje.getFullYear();
  const hora = hoje.getHours();
  const minuto = hoje.getMinutes();
  const dataFormatada = `${String(dia).padStart(2, '0')}/${String(mes).padStart(2, '0')}/${ano}  (${String(hora).padStart(2, '0')}:${String(minuto).padStart(2, '0')})`;
  const navigate = useNavigate();


  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
    } catch (error) {
      console.error('Erro ao fazer logout: ', error);
    }
  };

  return (
    <div style={{ width: '100%', boxShadow: 'blue' }}>
      <nav className={styles.navbar}>
        <div className={styles.divLogo}>
          <span className={styles.brand}>
            <img src={logo} alt="Logo" height={55} style={{ marginBottom: 10, marginRight: 10 }} /> POLICIA<span>PENAL</span>
          </span>
          {usuarioLogado && (
  <small style={{ display: 'block', maxWidth: '80%', margin: '0 auto', color: '#8a9175' }}>
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
   
      <b> &nbsp;{usuarioLogado}</b>
    </span>
  </small>
)}


        </div>
        <div className={styles.divLogo2} >
          <center><span className={styles.brand}>
            <p><img src={logo} alt="Logo" height={80} style={{ marginBottom: 10, marginRight: 10 }} /><h3>POLICIA<span>PENAL</span> </h3> </p>
          </span>
            {usuarioLogado && <small> <center><b style={{ marginRight: 5, color: '#8a9175' }}>{usuarioLogado}</b></center></small>}
          </center>
        </div>

        <ul className={styles.links_list}>
          <li>
          <b style={{ marginLeft: 50, fontSize:14, color:'#8a9175'}}>Atualização: {dataFormatada}</b> <button onClick={() => navigate('/') + window.location.reload() } style={{ fontWeight: 'bold',color:'#8bc34a', marginRight: 150, fontSize: 11, marginLeft:50 }}>
             <strong>ATUALIZAR<MdOutlineRefresh size={18} /></strong> 
            </button>
            <Link to="/"><button className={styles.botaoHeader}>GALERIAS</button></Link>
             <Link to="/movimento"><button className={styles.botaoHeader}>REGISTROS</button></Link>
            {!userVerificacao.includes("vis") && <Link to="/inclusao"><button className={styles.botaoHeader}>INCLUSÃO</button></Link>}
            <Link to="/contagem"><button className={styles.botaoHeader}>CONTAGEM</button></Link>
           <Link to="/localizador"><button className={styles.botaoHeader}>LOCALIZADOR</button></Link>
            {usuarioLogado && <button onClick={handleLogout} style={{ marginLeft: 70, backgroundColor: '#545f34', borderRadius: 5, padding: 1, color: '#8a9175' }}><small style={{ color: '#b8c495', fontSize: 12 }}>&nbsp;SAIR&nbsp;</small>  <GrLogout style={{ backgroundColor: '#545f34', color: 'white' }} size={12} /></button>}

          </li>
        </ul>
       
      </nav>
    </div>
  );
};

export default Header;
