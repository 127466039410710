import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      
      <p><small>KAIO<b>BERTOCCHI</b>RIBEIRO &copy; 2024</small></p>
    </footer>
  );
};

export default Footer;
