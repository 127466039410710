import { useEffect, useState } from 'react';
import styles from './Contagem.module.css'
import Celacontagem from '../../components/Celacontagem'
import { Button, Table } from 'reactstrap';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FiPrinter } from "react-icons/fi";
import logo from '../../assets/img/logo.png';
import excel from '../../assets/img/excel.png';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";


const Contagem = ({galerias, atualizarDadosMovimento2}) => {
 
  const [pagina, setPagina] = useState(6)
  const [ativaBotao, setAtivaBotao] = useState(6)
  const data = new Date();
  const dia = String(data.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se for necessário
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero, então adicionamos 1
  const ano = data.getFullYear();
  const agora = new Date();
  const hora = agora.getHours();
  const minutos = agora.getMinutes();
  const minutosFormatados = minutos.toString().padStart(2, '0');
  const [equipeAtual, setEquipeAtual] = useState('');

  useEffect(() => {
    atualizarDadosMovimento2()
  }, [])
  


  const exportToExcel = () => {
    // Função para extrair apenas os números da cela
    const extrairNumerosCela = (cela) => {
      if (!cela) return ''; // Se a cela for vazia, retorna uma string vazia
      return cela.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    };
  
    // Função para determinar a galeria com base na cela
    const getGaleria = (cela) => {
      // Verifica se a cela não está vazia
      if (cela && cela.trim() !== '') {
        const duasPrimeirasLetras = cela.substring(0, 2).toUpperCase();
        switch (duasPrimeirasLetras) {
          case 'DO':
            return 'P._DOMICILIAR';
          case 'AL':
            return 'ALOJAMENTO';
          case 'A0':
            return 'ALFA';
          case 'A1':
            return 'ALFA';
          case 'A2':
            return 'ALFA';
          case 'B0':
            return 'BRAVO';
          case 'B1':
            return 'BRAVO';
          case 'B2':
            return 'BRAVO';
          case 'C0':
            return 'CHARLIE';
          case 'C1':
            return 'CHARLIE';
          case 'C2':
            return 'CHARLIE';
          case 'E0':
            return 'ECHO';
          case 'E1':
            return 'ECHO';
          case 'E2':
            return 'ECHO';
          case 'F0':
            return 'FOX';
          case 'F1':
            return 'FOX';
          case 'F2':
            return 'FOX';
          case 'H0':
            return 'HOTEL';
          case 'H1':
            return 'HOTEL';
          case 'H2':
            return 'HOTEL';
          case 'G0':
            return 'GOLF';
          case 'G1':
            return 'GOLF';
          case 'G2':
            return 'GOLF';
          case 'DD':
            return 'DELTA';
          case 'DE':
            return 'DELTA';
          case 'TR':
            return 'TRIAGEM';
          case 'HO':
            return 'INT._HOSPITALAR';
          case 'HD':
            return 'HOTEL';
          case 'HE':
            return 'HOTEL';
          case 'PE':
            return 'PERNOITE';
          case 'PE':
            return 'PERNOITE';
          default:
            return ''; // Retorna uma string vazia se não corresponder a nenhum caso específico
        }
      } else {
        return ''; // Retorna uma string vazia se a cela estiver vazia
      }
    };
  
    // Filtra as galerias com cela não vazia e correspondente aos casos específicos
    const galeriasFiltradas = galerias.filter(item => {
      if (!item.cela || item.cela.trim() === '') return false; // Se a cela estiver vazia, não exporta
      const duasPrimeirasLetras = item.cela.substring(0, 2).toUpperCase();
      return ['AL','A0','A1','A2','B0','B1','B2','C0','C1','C2', 'DD', 'DE', 'TR', 'HO', 'HD', 'HE', 'PE', 'DO','E0','E1','E2','F0','F1','F2','G0','G1','G2'].includes(duasPrimeirasLetras); // Exporta apenas se as duas primeiras letras da cela corresponderem a essas letras
    });
  
    // Ordena os dados pelo campo "cela"
    const sortedData = galeriasFiltradas
      .sort((a, b) => (a.cela < b.cela ? -1 : 1))
      .map((item) => {
        // Extrai apenas os números da cela
        const celaNumerica = extrairNumerosCela(item.cela);
        // Determina a galeria com base na cela
        const galeria = getGaleria(item.cela);
        return { galeria, ...item };
      });
  
    // Define a ordem das colunas no Excel
    // Função para remover todas as letras de uma string
const removerLetras = (str) => {
  if (!str) return ''; // Retorna uma string vazia se o parâmetro for nulo ou vazio
  return str.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
};

// Define a ordem das colunas no Excel
const columnsOrder = ["infopen", "galeria", "cela", "nome", "origem", "selectDate" ,"entradaGaleria", "artigo", "selecao"];

// Mapeia os dados para garantir a ordem das colunas e remover letras da cela
const orderedData = sortedData.map(item => {
  const orderedItem = {};
  columnsOrder.forEach(column => {
    // Se a coluna for "cela", remove todas as letras antes de atribuir ao novo objeto
    if (column === "cela") {
      orderedItem[column] = removerLetras(item[column]);
    } else {
      orderedItem[column] = item[column];
    }
  });
  return orderedItem;
});

  
    // Converte os dados para uma planilha Excel
    const ws = XLSX.utils.json_to_sheet(orderedData);
  
    // Cria o workbook
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
    // Escreve o arquivo Excel
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
    // Cria um Blob para o arquivo Excel
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
    // Salva o arquivo Excel
    FileSaver.saveAs(dataBlob, `Contagem-${dia}-${mes}-${ano}.xlsx`);
  };
  
  

  const imprimirConteudo = () => {
    const conteudoParaImpressao = document.querySelector('.conteudo-para-impressao');
    if (conteudoParaImpressao) {
      window.print(conteudoParaImpressao);
    }
  };



  // criação das variáveis MAP GALERIA A
  const [pessoasSearchMapA01, setPessoasSearchMapA01] = useState('')
  const [pessoasSearchMapA02, setPessoasSearchMapA02] = useState('')
  const [pessoasSearchMapA03, setPessoasSearchMapA03] = useState('')
  const [pessoasSearchMapA04, setPessoasSearchMapA04] = useState('')
  const [pessoasSearchMapA05, setPessoasSearchMapA05] = useState('')
  const [pessoasSearchMapA06, setPessoasSearchMapA06] = useState('')
  const [pessoasSearchMapA07, setPessoasSearchMapA07] = useState('')
  const [pessoasSearchMapA08, setPessoasSearchMapA08] = useState('')
  const [pessoasSearchMapA09, setPessoasSearchMapA09] = useState('')
  const [pessoasSearchMapA10, setPessoasSearchMapA10] = useState('')
  const [pessoasSearchMapA11, setPessoasSearchMapA11] = useState('')
  const [pessoasSearchMapA12, setPessoasSearchMapA12] = useState('')
  const [pessoasSearchMapA13, setPessoasSearchMapA13] = useState('')
  const [pessoasSearchMapA14, setPessoasSearchMapA14] = useState('')
  const [pessoasSearchMapA15, setPessoasSearchMapA15] = useState('')
  const [pessoasSearchMapA16, setPessoasSearchMapA16] = useState('')
  const [pessoasSearchMapA17, setPessoasSearchMapA17] = useState('')
  const [pessoasSearchMapA18, setPessoasSearchMapA18] = useState('')
  const [pessoasSearchMapA19, setPessoasSearchMapA19] = useState('')
  const [pessoasSearchMapA20, setPessoasSearchMapA20] = useState('')


  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB01, setPessoasSearchMapB01] = useState('')
  const [pessoasSearchMapB02, setPessoasSearchMapB02] = useState('')
  const [pessoasSearchMapB03, setPessoasSearchMapB03] = useState('')
  const [pessoasSearchMapB04, setPessoasSearchMapB04] = useState('')
  const [pessoasSearchMapB05, setPessoasSearchMapB05] = useState('')
  const [pessoasSearchMapB06, setPessoasSearchMapB06] = useState('')
  const [pessoasSearchMapB07, setPessoasSearchMapB07] = useState('')
  const [pessoasSearchMapB08, setPessoasSearchMapB08] = useState('')
  const [pessoasSearchMapB09, setPessoasSearchMapB09] = useState('')
  const [pessoasSearchMapB10, setPessoasSearchMapB10] = useState('')
  const [pessoasSearchMapB11, setPessoasSearchMapB11] = useState('')
  const [pessoasSearchMapB12, setPessoasSearchMapB12] = useState('')
  const [pessoasSearchMapB13, setPessoasSearchMapB13] = useState('')
  const [pessoasSearchMapB14, setPessoasSearchMapB14] = useState('')
  const [pessoasSearchMapB15, setPessoasSearchMapB15] = useState('')
  const [pessoasSearchMapB16, setPessoasSearchMapB16] = useState('')
  const [pessoasSearchMapB17, setPessoasSearchMapB17] = useState('')
  const [pessoasSearchMapB18, setPessoasSearchMapB18] = useState('')
  const [pessoasSearchMapB19, setPessoasSearchMapB19] = useState('')
  const [pessoasSearchMapB20, setPessoasSearchMapB20] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC01, setPessoasSearchMapC01] = useState('')
  const [pessoasSearchMapC02, setPessoasSearchMapC02] = useState('')
  const [pessoasSearchMapC03, setPessoasSearchMapC03] = useState('')
  const [pessoasSearchMapC04, setPessoasSearchMapC04] = useState('')
  const [pessoasSearchMapC05, setPessoasSearchMapC05] = useState('')
  const [pessoasSearchMapC06, setPessoasSearchMapC06] = useState('')
  const [pessoasSearchMapC07, setPessoasSearchMapC07] = useState('')
  const [pessoasSearchMapC08, setPessoasSearchMapC08] = useState('')
  const [pessoasSearchMapC09, setPessoasSearchMapC09] = useState('')
  const [pessoasSearchMapC10, setPessoasSearchMapC10] = useState('')
  const [pessoasSearchMapC11, setPessoasSearchMapC11] = useState('')
  const [pessoasSearchMapC12, setPessoasSearchMapC12] = useState('')
  const [pessoasSearchMapC13, setPessoasSearchMapC13] = useState('')
  const [pessoasSearchMapC14, setPessoasSearchMapC14] = useState('')
  const [pessoasSearchMapC15, setPessoasSearchMapC15] = useState('')
  const [pessoasSearchMapC16, setPessoasSearchMapC16] = useState('')
  const [pessoasSearchMapC17, setPessoasSearchMapC17] = useState('')
  const [pessoasSearchMapC18, setPessoasSearchMapC18] = useState('')
  const [pessoasSearchMapC19, setPessoasSearchMapC19] = useState('')
  const [pessoasSearchMapC20, setPessoasSearchMapC20] = useState('')


  // CRIAÇÃO DAS VARIAVEIS GALERIA D (DD + DE)

  const [pessoasSearchMapDD01, setPessoasSearchMapDD01] = useState('')
  const [pessoasSearchMapDD02, setPessoasSearchMapDD02] = useState('')
  const [pessoasSearchMapDD03, setPessoasSearchMapDD03] = useState('')
  const [pessoasSearchMapDD04, setPessoasSearchMapDD04] = useState('')
  const [pessoasSearchMapDD05, setPessoasSearchMapDD05] = useState('')

  const [pessoasSearchMapDE01, setPessoasSearchMapDE01] = useState('')
  const [pessoasSearchMapDE02, setPessoasSearchMapDE02] = useState('')
  const [pessoasSearchMapDE03, setPessoasSearchMapDE03] = useState('')
  const [pessoasSearchMapDE04, setPessoasSearchMapDE04] = useState('')
  const [pessoasSearchMapDE05, setPessoasSearchMapDE05] = useState('')


  // GALERIA E

  const [pessoasSearchMapE01, setPessoasSearchMapE01] = useState('')
  const [pessoasSearchMapE02, setPessoasSearchMapE02] = useState('')
  const [pessoasSearchMapE03, setPessoasSearchMapE03] = useState('')
  const [pessoasSearchMapE04, setPessoasSearchMapE04] = useState('')
  const [pessoasSearchMapE05, setPessoasSearchMapE05] = useState('')
  const [pessoasSearchMapE06, setPessoasSearchMapE06] = useState('')
  const [pessoasSearchMapE07, setPessoasSearchMapE07] = useState('')
  const [pessoasSearchMapE08, setPessoasSearchMapE08] = useState('')
  const [pessoasSearchMapE09, setPessoasSearchMapE09] = useState('')
  const [pessoasSearchMapE10, setPessoasSearchMapE10] = useState('')
  const [pessoasSearchMapE11, setPessoasSearchMapE11] = useState('')
  const [pessoasSearchMapE12, setPessoasSearchMapE12] = useState('')
  const [pessoasSearchMapE13, setPessoasSearchMapE13] = useState('')
  const [pessoasSearchMapE14, setPessoasSearchMapE14] = useState('')
  const [pessoasSearchMapE15, setPessoasSearchMapE15] = useState('')
  const [pessoasSearchMapE16, setPessoasSearchMapE16] = useState('')
  const [pessoasSearchMapE17, setPessoasSearchMapE17] = useState('')
  const [pessoasSearchMapE18, setPessoasSearchMapE18] = useState('')
  const [pessoasSearchMapE19, setPessoasSearchMapE19] = useState('')
  const [pessoasSearchMapE20, setPessoasSearchMapE20] = useState('')

  // GALERIA F

  const [pessoasSearchMapF01, setPessoasSearchMapF01] = useState('')
  const [pessoasSearchMapF02, setPessoasSearchMapF02] = useState('')
  const [pessoasSearchMapF03, setPessoasSearchMapF03] = useState('')
  const [pessoasSearchMapF04, setPessoasSearchMapF04] = useState('')
  const [pessoasSearchMapF05, setPessoasSearchMapF05] = useState('')
  const [pessoasSearchMapF06, setPessoasSearchMapF06] = useState('')
  const [pessoasSearchMapF07, setPessoasSearchMapF07] = useState('')
  const [pessoasSearchMapF08, setPessoasSearchMapF08] = useState('')
  const [pessoasSearchMapF09, setPessoasSearchMapF09] = useState('')
  const [pessoasSearchMapF10, setPessoasSearchMapF10] = useState('')
  const [pessoasSearchMapF11, setPessoasSearchMapF11] = useState('')
  const [pessoasSearchMapF12, setPessoasSearchMapF12] = useState('')
  const [pessoasSearchMapF13, setPessoasSearchMapF13] = useState('')
  const [pessoasSearchMapF14, setPessoasSearchMapF14] = useState('')
  const [pessoasSearchMapF15, setPessoasSearchMapF15] = useState('')
  const [pessoasSearchMapF16, setPessoasSearchMapF16] = useState('')
  const [pessoasSearchMapF17, setPessoasSearchMapF17] = useState('')
  const [pessoasSearchMapF18, setPessoasSearchMapF18] = useState('')
  const [pessoasSearchMapF19, setPessoasSearchMapF19] = useState('')
  const [pessoasSearchMapF20, setPessoasSearchMapF20] = useState('')


  // GALERIA G
  const [pessoasSearchMapG01, setPessoasSearchMapG01] = useState('')
  const [pessoasSearchMapG02, setPessoasSearchMapG02] = useState('')
  const [pessoasSearchMapG03, setPessoasSearchMapG03] = useState('')
  const [pessoasSearchMapG04, setPessoasSearchMapG04] = useState('')
  const [pessoasSearchMapG05, setPessoasSearchMapG05] = useState('')
  const [pessoasSearchMapG06, setPessoasSearchMapG06] = useState('')
  const [pessoasSearchMapG07, setPessoasSearchMapG07] = useState('')
  const [pessoasSearchMapG08, setPessoasSearchMapG08] = useState('')
  const [pessoasSearchMapG09, setPessoasSearchMapG09] = useState('')
  const [pessoasSearchMapG10, setPessoasSearchMapG10] = useState('')
  const [pessoasSearchMapG11, setPessoasSearchMapG11] = useState('')
  const [pessoasSearchMapG12, setPessoasSearchMapG12] = useState('')
  const [pessoasSearchMapG13, setPessoasSearchMapG13] = useState('')
  const [pessoasSearchMapG14, setPessoasSearchMapG14] = useState('')
  const [pessoasSearchMapG15, setPessoasSearchMapG15] = useState('')
  const [pessoasSearchMapG16, setPessoasSearchMapG16] = useState('')
  const [pessoasSearchMapG17, setPessoasSearchMapG17] = useState('')
  const [pessoasSearchMapG18, setPessoasSearchMapG18] = useState('')
  const [pessoasSearchMapG19, setPessoasSearchMapG19] = useState('')
  const [pessoasSearchMapG20, setPessoasSearchMapG20] = useState('')


  // GALERIA H

  const [pessoasSearchMapHD01, setPessoasSearchMapHD01] = useState('')
  const [pessoasSearchMapHD02, setPessoasSearchMapHD02] = useState('')
  const [pessoasSearchMapHD03, setPessoasSearchMapHD03] = useState('')
  const [pessoasSearchMapHD04, setPessoasSearchMapHD04] = useState('')
  const [pessoasSearchMapHD05, setPessoasSearchMapHD05] = useState('')

  const [pessoasSearchMapHE01, setPessoasSearchMapHE01] = useState('')
  const [pessoasSearchMapHE02, setPessoasSearchMapHE02] = useState('')
  const [pessoasSearchMapHE03, setPessoasSearchMapHE03] = useState('')
  const [pessoasSearchMapHE04, setPessoasSearchMapHE04] = useState('')
  const [pessoasSearchMapHE05, setPessoasSearchMapHE05] = useState('')


  //variaveis da triagem

  const [pessoasSearchMapTR, setPessoasSearchMapTR] = useState('')
  const [pessoasSearchMapTR01, setPessoasSearchMapTR01] = useState('')
  const [pessoasSearchMapTR02, setPessoasSearchMapTR02] = useState('')
  const [pessoasSearchMapTR03, setPessoasSearchMapTR03] = useState('')
  const [pessoasSearchMapTR04, setPessoasSearchMapTR04] = useState('')
  const [pessoasSearchMapTR05, setPessoasSearchMapTR05] = useState('')
  const [pessoasSearchMapTR06, setPessoasSearchMapTR06] = useState('')


  // ALOJAMENTO

  const [pessoasSearchMapALOJAMENTO, setPessoasSearchMapALOJAMENTO] = useState('')


  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE

  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')
  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')
  const [pessoasDomiciliar, setPessoasDomiciliar] = useState('')
  const [pessoasHospital, setPessoasHospital] = useState('')
  const [pessoasPernoite, setPessoasPernoite] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA01total, setPessoasSearchMapA01total] = useState('')
  const [pessoasSearchMapA02total, setPessoasSearchMapA02total] = useState('')
  const [pessoasSearchMapA03total, setPessoasSearchMapA03total] = useState('')
  const [pessoasSearchMapA04total, setPessoasSearchMapA04total] = useState('')
  const [pessoasSearchMapA05total, setPessoasSearchMapA05total] = useState('')
  const [pessoasSearchMapA06total, setPessoasSearchMapA06total] = useState('')
  const [pessoasSearchMapA07total, setPessoasSearchMapA07total] = useState('')
  const [pessoasSearchMapA08total, setPessoasSearchMapA08total] = useState('')
  const [pessoasSearchMapA09total, setPessoasSearchMapA09total] = useState('')
  const [pessoasSearchMapA10total, setPessoasSearchMapA10total] = useState('')
  const [pessoasSearchMapA11total, setPessoasSearchMapA11total] = useState('')
  const [pessoasSearchMapA12total, setPessoasSearchMapA12total] = useState('')
  const [pessoasSearchMapA13total, setPessoasSearchMapA13total] = useState('')
  const [pessoasSearchMapA14total, setPessoasSearchMapA14total] = useState('')
  const [pessoasSearchMapA15total, setPessoasSearchMapA15total] = useState('')
  const [pessoasSearchMapA16total, setPessoasSearchMapA16total] = useState('')
  const [pessoasSearchMapA17total, setPessoasSearchMapA17total] = useState('')
  const [pessoasSearchMapA18total, setPessoasSearchMapA18total] = useState('')
  const [pessoasSearchMapA19total, setPessoasSearchMapA19total] = useState('')
  const [pessoasSearchMapA20total, setPessoasSearchMapA20total] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB01total, setPessoasSearchMapB01total] = useState('')
  const [pessoasSearchMapB02total, setPessoasSearchMapB02total] = useState('')
  const [pessoasSearchMapB03total, setPessoasSearchMapB03total] = useState('')
  const [pessoasSearchMapB04total, setPessoasSearchMapB04total] = useState('')
  const [pessoasSearchMapB05total, setPessoasSearchMapB05total] = useState('')
  const [pessoasSearchMapB06total, setPessoasSearchMapB06total] = useState('')
  const [pessoasSearchMapB07total, setPessoasSearchMapB07total] = useState('')
  const [pessoasSearchMapB08total, setPessoasSearchMapB08total] = useState('')
  const [pessoasSearchMapB09total, setPessoasSearchMapB09total] = useState('')
  const [pessoasSearchMapB10total, setPessoasSearchMapB10total] = useState('')
  const [pessoasSearchMapB11total, setPessoasSearchMapB11total] = useState('')
  const [pessoasSearchMapB12total, setPessoasSearchMapB12total] = useState('')
  const [pessoasSearchMapB13total, setPessoasSearchMapB13total] = useState('')
  const [pessoasSearchMapB14total, setPessoasSearchMapB14total] = useState('')
  const [pessoasSearchMapB15total, setPessoasSearchMapB15total] = useState('')
  const [pessoasSearchMapB16total, setPessoasSearchMapB16total] = useState('')
  const [pessoasSearchMapB17total, setPessoasSearchMapB17total] = useState('')
  const [pessoasSearchMapB18total, setPessoasSearchMapB18total] = useState('')
  const [pessoasSearchMapB19total, setPessoasSearchMapB19total] = useState('')
  const [pessoasSearchMapB20total, setPessoasSearchMapB20total] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC01total, setPessoasSearchMapC01total] = useState('')
  const [pessoasSearchMapC02total, setPessoasSearchMapC02total] = useState('')
  const [pessoasSearchMapC03total, setPessoasSearchMapC03total] = useState('')
  const [pessoasSearchMapC04total, setPessoasSearchMapC04total] = useState('')
  const [pessoasSearchMapC05total, setPessoasSearchMapC05total] = useState('')
  const [pessoasSearchMapC06total, setPessoasSearchMapC06total] = useState('')
  const [pessoasSearchMapC07total, setPessoasSearchMapC07total] = useState('')
  const [pessoasSearchMapC08total, setPessoasSearchMapC08total] = useState('')
  const [pessoasSearchMapC09total, setPessoasSearchMapC09total] = useState('')
  const [pessoasSearchMapC10total, setPessoasSearchMapC10total] = useState('')
  const [pessoasSearchMapC11total, setPessoasSearchMapC11total] = useState('')
  const [pessoasSearchMapC12total, setPessoasSearchMapC12total] = useState('')
  const [pessoasSearchMapC13total, setPessoasSearchMapC13total] = useState('')
  const [pessoasSearchMapC14total, setPessoasSearchMapC14total] = useState('')
  const [pessoasSearchMapC15total, setPessoasSearchMapC15total] = useState('')
  const [pessoasSearchMapC16total, setPessoasSearchMapC16total] = useState('')
  const [pessoasSearchMapC17total, setPessoasSearchMapC17total] = useState('')
  const [pessoasSearchMapC18total, setPessoasSearchMapC18total] = useState('')
  const [pessoasSearchMapC19total, setPessoasSearchMapC19total] = useState('')
  const [pessoasSearchMapC20total, setPessoasSearchMapC20total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapDD01total, setPessoasSearchMapDD01total] = useState('')
  const [pessoasSearchMapDD02total, setPessoasSearchMapDD02total] = useState('')
  const [pessoasSearchMapDD03total, setPessoasSearchMapDD03total] = useState('')
  const [pessoasSearchMapDD04total, setPessoasSearchMapDD04total] = useState('')
  const [pessoasSearchMapDD05total, setPessoasSearchMapDD05total] = useState('')
  const [pessoasSearchMapDE01total, setPessoasSearchMapDE01total] = useState('')
  const [pessoasSearchMapDE02total, setPessoasSearchMapDE02total] = useState('')
  const [pessoasSearchMapDE03total, setPessoasSearchMapDE03total] = useState('')
  const [pessoasSearchMapDE04total, setPessoasSearchMapDE04total] = useState('')
  const [pessoasSearchMapDE05total, setPessoasSearchMapDE05total] = useState('')

  //GALERIA E

  const [pessoasSearchMapE01total, setPessoasSearchMapE01total] = useState('')
  const [pessoasSearchMapE02total, setPessoasSearchMapE02total] = useState('')
  const [pessoasSearchMapE03total, setPessoasSearchMapE03total] = useState('')
  const [pessoasSearchMapE04total, setPessoasSearchMapE04total] = useState('')
  const [pessoasSearchMapE05total, setPessoasSearchMapE05total] = useState('')
  const [pessoasSearchMapE06total, setPessoasSearchMapE06total] = useState('')
  const [pessoasSearchMapE07total, setPessoasSearchMapE07total] = useState('')
  const [pessoasSearchMapE08total, setPessoasSearchMapE08total] = useState('')
  const [pessoasSearchMapE09total, setPessoasSearchMapE09total] = useState('')
  const [pessoasSearchMapE10total, setPessoasSearchMapE10total] = useState('')
  const [pessoasSearchMapE11total, setPessoasSearchMapE11total] = useState('')
  const [pessoasSearchMapE12total, setPessoasSearchMapE12total] = useState('')
  const [pessoasSearchMapE13total, setPessoasSearchMapE13total] = useState('')
  const [pessoasSearchMapE14total, setPessoasSearchMapE14total] = useState('')
  const [pessoasSearchMapE15total, setPessoasSearchMapE15total] = useState('')
  const [pessoasSearchMapE16total, setPessoasSearchMapE16total] = useState('')
  const [pessoasSearchMapE17total, setPessoasSearchMapE17total] = useState('')
  const [pessoasSearchMapE18total, setPessoasSearchMapE18total] = useState('')
  const [pessoasSearchMapE19total, setPessoasSearchMapE19total] = useState('')
  const [pessoasSearchMapE20total, setPessoasSearchMapE20total] = useState('')


  //GALERIA F

  const [pessoasSearchMapF01total, setPessoasSearchMapF01total] = useState('')
  const [pessoasSearchMapF02total, setPessoasSearchMapF02total] = useState('')
  const [pessoasSearchMapF03total, setPessoasSearchMapF03total] = useState('')
  const [pessoasSearchMapF04total, setPessoasSearchMapF04total] = useState('')
  const [pessoasSearchMapF05total, setPessoasSearchMapF05total] = useState('')
  const [pessoasSearchMapF06total, setPessoasSearchMapF06total] = useState('')
  const [pessoasSearchMapF07total, setPessoasSearchMapF07total] = useState('')
  const [pessoasSearchMapF08total, setPessoasSearchMapF08total] = useState('')
  const [pessoasSearchMapF09total, setPessoasSearchMapF09total] = useState('')
  const [pessoasSearchMapF10total, setPessoasSearchMapF10total] = useState('')
  const [pessoasSearchMapF11total, setPessoasSearchMapF11total] = useState('')
  const [pessoasSearchMapF12total, setPessoasSearchMapF12total] = useState('')
  const [pessoasSearchMapF13total, setPessoasSearchMapF13total] = useState('')
  const [pessoasSearchMapF14total, setPessoasSearchMapF14total] = useState('')
  const [pessoasSearchMapF15total, setPessoasSearchMapF15total] = useState('')
  const [pessoasSearchMapF16total, setPessoasSearchMapF16total] = useState('')
  const [pessoasSearchMapF17total, setPessoasSearchMapF17total] = useState('')
  const [pessoasSearchMapF18total, setPessoasSearchMapF18total] = useState('')
  const [pessoasSearchMapF19total, setPessoasSearchMapF19total] = useState('')
  const [pessoasSearchMapF20total, setPessoasSearchMapF20total] = useState('')

  // GALERIA G
  const [pessoasSearchMapG01total, setPessoasSearchMapG01total] = useState('')
  const [pessoasSearchMapG02total, setPessoasSearchMapG02total] = useState('')
  const [pessoasSearchMapG03total, setPessoasSearchMapG03total] = useState('')
  const [pessoasSearchMapG04total, setPessoasSearchMapG04total] = useState('')
  const [pessoasSearchMapG05total, setPessoasSearchMapG05total] = useState('')
  const [pessoasSearchMapG06total, setPessoasSearchMapG06total] = useState('')
  const [pessoasSearchMapG07total, setPessoasSearchMapG07total] = useState('')
  const [pessoasSearchMapG08total, setPessoasSearchMapG08total] = useState('')
  const [pessoasSearchMapG09total, setPessoasSearchMapG09total] = useState('')
  const [pessoasSearchMapG10total, setPessoasSearchMapG10total] = useState('')
  const [pessoasSearchMapG11total, setPessoasSearchMapG11total] = useState('')
  const [pessoasSearchMapG12total, setPessoasSearchMapG12total] = useState('')
  const [pessoasSearchMapG13total, setPessoasSearchMapG13total] = useState('')
  const [pessoasSearchMapG14total, setPessoasSearchMapG14total] = useState('')
  const [pessoasSearchMapG15total, setPessoasSearchMapG15total] = useState('')
  const [pessoasSearchMapG16total, setPessoasSearchMapG16total] = useState('')
  const [pessoasSearchMapG17total, setPessoasSearchMapG17total] = useState('')
  const [pessoasSearchMapG18total, setPessoasSearchMapG18total] = useState('')
  const [pessoasSearchMapG19total, setPessoasSearchMapG19total] = useState('')
  const [pessoasSearchMapG20total, setPessoasSearchMapG20total] = useState('')


  // GALERIA H 
  const [pessoasSearchMapHD01total, setPessoasSearchMapHD01total] = useState(0)
  const [pessoasSearchMapHD02total, setPessoasSearchMapHD02total] = useState(0)
  const [pessoasSearchMapHD03total, setPessoasSearchMapHD03total] = useState(0)
  const [pessoasSearchMapHD04total, setPessoasSearchMapHD04total] = useState(0)
  const [pessoasSearchMapHD05total, setPessoasSearchMapHD05total] = useState(0)
  const [pessoasSearchMapHE01total, setPessoasSearchMapHE01total] = useState(0)
  const [pessoasSearchMapHE02total, setPessoasSearchMapHE02total] = useState(0)
  const [pessoasSearchMapHE03total, setPessoasSearchMapHE03total] = useState(0)
  const [pessoasSearchMapHE04total, setPessoasSearchMapHE04total] = useState(0)
  const [pessoasSearchMapHE05total, setPessoasSearchMapHE05total] = useState(0)

  //variaveis da triagem


  const [pessoasSearchMapTRtotal, setPessoasSearchMapTRtotal] = useState('')
  const [pessoasSearchMapTR01total, setPessoasSearchMapTR01total] = useState('')
  const [pessoasSearchMapTR02total, setPessoasSearchMapTR02total] = useState('')
  const [pessoasSearchMapTR03total, setPessoasSearchMapTR03total] = useState('')
  const [pessoasSearchMapTR04total, setPessoasSearchMapTR04total] = useState('')
  const [pessoasSearchMapTR05total, setPessoasSearchMapTR05total] = useState('')
  const [pessoasSearchMapTR06total, setPessoasSearchMapTR06total] = useState('')

  const [pessoasSearchMapALOJAMENTOtotal, setPessoasSearchMapALOJAMENTOtotal] = useState('')

  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA01total +
    pessoasSearchMapA02total +
    pessoasSearchMapA03total +
    pessoasSearchMapA04total +
    pessoasSearchMapA05total +
    pessoasSearchMapA06total +
    pessoasSearchMapA07total +
    pessoasSearchMapA08total +
    pessoasSearchMapA09total +
    pessoasSearchMapA10total +
    pessoasSearchMapA11total +
    pessoasSearchMapA12total +
    pessoasSearchMapA13total +
    pessoasSearchMapA14total +
    pessoasSearchMapA15total +
    pessoasSearchMapA16total +
    pessoasSearchMapA17total +
    pessoasSearchMapA18total +
    pessoasSearchMapA19total +
    pessoasSearchMapA20total




  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB01total +
    pessoasSearchMapB02total +
    pessoasSearchMapB03total +
    pessoasSearchMapB04total +
    pessoasSearchMapB05total +
    pessoasSearchMapB06total +
    pessoasSearchMapB07total +
    pessoasSearchMapB08total +
    pessoasSearchMapB09total +
    pessoasSearchMapB10total +
    pessoasSearchMapB11total +
    pessoasSearchMapB12total +
    pessoasSearchMapB13total +
    pessoasSearchMapB14total +
    pessoasSearchMapB15total +
    pessoasSearchMapB16total +
    pessoasSearchMapB17total +
    pessoasSearchMapB18total +
    pessoasSearchMapB19total +
    pessoasSearchMapB20total


  // QUADRANTES BRAVO 


  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC01total +
    pessoasSearchMapC02total +
    pessoasSearchMapC03total +
    pessoasSearchMapC04total +
    pessoasSearchMapC05total +
    pessoasSearchMapC06total +
    pessoasSearchMapC07total +
    pessoasSearchMapC08total +
    pessoasSearchMapC09total +
    pessoasSearchMapC10total +
    pessoasSearchMapC11total +
    pessoasSearchMapC12total +
    pessoasSearchMapC13total +
    pessoasSearchMapC14total +
    pessoasSearchMapC15total +
    pessoasSearchMapC16total +
    pessoasSearchMapC17total +
    pessoasSearchMapC18total +
    pessoasSearchMapC19total +
    pessoasSearchMapC20total


  // QUADRANTES CHARLIE 







  const totalSomaD =

    pessoasSearchMapDD01total +
    pessoasSearchMapDD02total +
    pessoasSearchMapDD03total +
    pessoasSearchMapDD04total +
    pessoasSearchMapDD05total +
    pessoasSearchMapDE01total +
    pessoasSearchMapDE02total +
    pessoasSearchMapDE03total +
    pessoasSearchMapDE04total +
    pessoasSearchMapDE05total




  const totalSomaE =
    pessoasSearchMapE01total +
    pessoasSearchMapE02total +
    pessoasSearchMapE03total +
    pessoasSearchMapE04total +
    pessoasSearchMapE05total +
    pessoasSearchMapE06total +
    pessoasSearchMapE07total +
    pessoasSearchMapE08total +
    pessoasSearchMapE09total +
    pessoasSearchMapE10total +
    pessoasSearchMapE11total +
    pessoasSearchMapE12total +
    pessoasSearchMapE13total +
    pessoasSearchMapE14total +
    pessoasSearchMapE15total +
    pessoasSearchMapE16total +
    pessoasSearchMapE17total +
    pessoasSearchMapE18total +
    pessoasSearchMapE19total +
    pessoasSearchMapE20total

  const totalSomaF =
    pessoasSearchMapF01total +
    pessoasSearchMapF02total +
    pessoasSearchMapF03total +
    pessoasSearchMapF04total +
    pessoasSearchMapF05total +
    pessoasSearchMapF06total +
    pessoasSearchMapF07total +
    pessoasSearchMapF08total +
    pessoasSearchMapF09total +
    pessoasSearchMapF10total +
    pessoasSearchMapF11total +
    pessoasSearchMapF12total +
    pessoasSearchMapF13total +
    pessoasSearchMapF14total +
    pessoasSearchMapF15total +
    pessoasSearchMapF16total +
    pessoasSearchMapF17total +
    pessoasSearchMapF18total +
    pessoasSearchMapF19total +
    pessoasSearchMapF20total

  const totalSomaG =
    pessoasSearchMapG01total +
    pessoasSearchMapG02total +
    pessoasSearchMapG03total +
    pessoasSearchMapG04total +
    pessoasSearchMapG05total +
    pessoasSearchMapG06total +
    pessoasSearchMapG07total +
    pessoasSearchMapG08total +
    pessoasSearchMapG09total +
    pessoasSearchMapG10total +
    pessoasSearchMapG11total +
    pessoasSearchMapG12total +
    pessoasSearchMapG13total +
    pessoasSearchMapG14total +
    pessoasSearchMapG15total +
    pessoasSearchMapG16total +
    pessoasSearchMapG17total +
    pessoasSearchMapG18total +
    pessoasSearchMapG19total +
    pessoasSearchMapG20total

  const totalSomaH =

    pessoasSearchMapHD01total +
    pessoasSearchMapHD02total +
    pessoasSearchMapHD03total +
    pessoasSearchMapHD04total +
    pessoasSearchMapHD05total +
    pessoasSearchMapHE01total +
    pessoasSearchMapHE02total +
    pessoasSearchMapHE03total +
    pessoasSearchMapHE04total +
    pessoasSearchMapHE05total

  const totalSomaTR =
    pessoasSearchMapTRtotal +
    pessoasSearchMapTR01total +
    pessoasSearchMapTR02total +
    pessoasSearchMapTR03total +
    pessoasSearchMapTR04total +
    pessoasSearchMapTR05total +
    pessoasSearchMapTR06total


  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaE +
    totalSomaF +
    totalSomaG +
    totalSomaH +
    totalSomaTR +
    pessoasSearchMapALOJAMENTOtotal

  const totalGeral = totalUp + pessoasDomiciliartotal + pessoasHospitaltotal + pessoasPernoitetotal


  const paginaCapa = () => {

    setAtivaBotao(6)
    setPagina(6)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(1)
    setPagina(1)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaE = () => {

    setAtivaBotao(8)
    setPagina(8)

  }
  const paginaGaleriaF = () => {

    setAtivaBotao(9)
    setPagina(9)

  }
  const paginaGaleriaG = () => {

    setAtivaBotao(10)
    setPagina(10)

  }
  const paginaGaleriaTR = () => {

    setAtivaBotao(11)
    setPagina(11)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGeral = () => {

    setAtivaBotao(5)
    setPagina(5)

  }

  
// A101
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA01 = "A01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA01(filteredResults);
    setPessoasSearchMapA01total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA01).toUpperCase())
}, [galerias])

// A02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA02 = "A02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA02(filteredResults);
    setPessoasSearchMapA02total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA02).toUpperCase())
}, [galerias])

// A03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA03 = "A03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA03(filteredResults);
    setPessoasSearchMapA03total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA03).toUpperCase())
}, [galerias])

// A04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA04 = "A04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA04(filteredResults);
    setPessoasSearchMapA04total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA04).toUpperCase())
}, [galerias])

// A05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA05 = "A05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA05(filteredResults);
    setPessoasSearchMapA05total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA05).toUpperCase())
}, [galerias])

// A06
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA06 = "A06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA06(filteredResults);
    setPessoasSearchMapA06total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA06).toUpperCase())
}, [galerias])

// A07
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA07 = "A07";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA07(filteredResults);
    setPessoasSearchMapA07total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA07).toUpperCase())
}, [galerias])


// A08
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA08 = "A08";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA08(filteredResults);
    setPessoasSearchMapA08total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA08).toUpperCase())
}, [galerias])
// A09
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA09 = "A09";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA09(filteredResults);
    setPessoasSearchMapA09total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA09).toUpperCase())
}, [galerias])

// A10
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA10 = "A10";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA10(filteredResults);
    setPessoasSearchMapA10total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA10).toUpperCase())
}, [galerias])
// A11
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA11 = "A11";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA11(filteredResults);
    setPessoasSearchMapA11total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA11).toUpperCase())
}, [galerias])

// A12
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA12 = "A12";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA12(filteredResults);
    setPessoasSearchMapA12total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA12).toUpperCase())
}, [galerias])


// A13
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA13 = "A13";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA13(filteredResults);
    setPessoasSearchMapA13total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA13).toUpperCase())
}, [galerias])

// A14
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA14 = "A14";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA14(filteredResults);
    setPessoasSearchMapA14total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA14).toUpperCase())
}, [galerias])

// A15
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA15 = "A15";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA15(filteredResults);
    setPessoasSearchMapA15total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA15).toUpperCase())
}, [galerias])

// A16
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA16 = "A16";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA16(filteredResults);
    setPessoasSearchMapA16total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA16).toUpperCase())
}, [galerias])


// A17
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA17 = "A17";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA17(filteredResults);
    setPessoasSearchMapA17total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA17).toUpperCase())
}, [galerias])

// A18
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA18 = "A18";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA18(filteredResults);
    setPessoasSearchMapA18total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA18).toUpperCase())
}, [galerias])

// A19
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA19 = "A19";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA19(filteredResults);
    setPessoasSearchMapA19total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA19).toUpperCase())
}, [galerias])

// A20
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaA20 = "A20";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapA20(filteredResults);
    setPessoasSearchMapA20total(filteredResults.length)

  }
  //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
  performSearch((celaA20).toUpperCase())
}, [galerias])


// B01
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB01 = "B01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB01(filteredResults);
    setPessoasSearchMapB01total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB01).toUpperCase())
}, [galerias])

// B02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB02 = "B02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB02(filteredResults);
    setPessoasSearchMapB02total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB02).toUpperCase())
}, [galerias])

// B03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB03 = "B03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB03(filteredResults);
    setPessoasSearchMapB03total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB03).toUpperCase())
}, [galerias])

// B04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB04 = "B04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB04(filteredResults);
    setPessoasSearchMapB04total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB04).toUpperCase())
}, [galerias])
// B05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB05 = "B05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB05(filteredResults);
    setPessoasSearchMapB05total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB05).toUpperCase())
}, [galerias])

// B06
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB06 = "B06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB06(filteredResults);
    setPessoasSearchMapB06total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB06).toUpperCase())
}, [galerias])

// B07
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB07 = "B07";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB07(filteredResults);
    setPessoasSearchMapB07total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB07).toUpperCase())
}, [galerias])


// B08
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB08 = "B08";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB08(filteredResults);
    setPessoasSearchMapB08total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB08).toUpperCase())
}, [galerias])
// B09
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB09 = "B09";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB09(filteredResults);
    setPessoasSearchMapB09total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB09).toUpperCase())
}, [galerias])

// B10
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB10 = "B10";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB10(filteredResults);
    setPessoasSearchMapB10total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB10).toUpperCase())
}, [galerias])
// B11
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB11 = "B11";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB11(filteredResults);
    setPessoasSearchMapB11total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB11).toUpperCase())
}, [galerias])

// B12
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB12 = "B12";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB12(filteredResults);
    setPessoasSearchMapB12total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB12).toUpperCase())
}, [galerias])


// B13
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB13 = "B13";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB13(filteredResults);
    setPessoasSearchMapB13total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB13).toUpperCase())
}, [galerias])

// B14
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB14 = "B14";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB14(filteredResults);
    setPessoasSearchMapB14total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB14).toUpperCase())
}, [galerias])

// B15
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB15 = "B15";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB15(filteredResults);
    setPessoasSearchMapB15total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB15).toUpperCase())
}, [galerias])

// B16
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB16 = "B16";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB16(filteredResults);
    setPessoasSearchMapB16total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB16).toUpperCase())
}, [galerias])


// B17
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB17 = "B17";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB17(filteredResults);
    setPessoasSearchMapB17total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB17).toUpperCase())
}, [galerias])

// B18
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB18 = "B18";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB18(filteredResults);
    setPessoasSearchMapB18total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB18).toUpperCase())
}, [galerias])

// B19
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB19 = "B19";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB19(filteredResults);
    setPessoasSearchMapB19total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB19).toUpperCase())
}, [galerias])

// B20
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaB20 = "B20";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapB20(filteredResults);
    setPessoasSearchMapB20total(filteredResults.length)

  }
  //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
  performSearch((celaB20).toUpperCase())
}, [galerias])



//C C01
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC01 = "C01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC01(filteredResults);
    setPessoasSearchMapC01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC01).toUpperCase())
}, [galerias])

// C02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC02 = "C02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC02(filteredResults);
    setPessoasSearchMapC02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC02).toUpperCase())
}, [galerias])

// C03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC03 = "C03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC03(filteredResults);
    setPessoasSearchMapC03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC03).toUpperCase())
}, [galerias])

// C04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC04 = "C04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC04(filteredResults);
    setPessoasSearchMapC04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC04).toUpperCase())
}, [galerias])
// C05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC05 = "C05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC05(filteredResults);
    setPessoasSearchMapC05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC05).toUpperCase())
}, [galerias])

// C06
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC06 = "C06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC06(filteredResults);
    setPessoasSearchMapC06total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC06).toUpperCase())
}, [galerias])

// C07
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC07 = "C07";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC07(filteredResults);
    setPessoasSearchMapC07total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC07).toUpperCase())
}, [galerias])


// C08
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC08 = "C08";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC08(filteredResults);
    setPessoasSearchMapC08total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC08).toUpperCase())
}, [galerias])
// C09
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC09 = "C09";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC09(filteredResults);
    setPessoasSearchMapC09total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC09).toUpperCase())
}, [galerias])

// C10
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC10 = "C10";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC10(filteredResults);
    setPessoasSearchMapC10total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC10).toUpperCase())
}, [galerias])
// C11
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC11 = "C11";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC11(filteredResults);
    setPessoasSearchMapC11total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC11).toUpperCase())
}, [galerias])

// C12
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC12 = "C12";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC12(filteredResults);
    setPessoasSearchMapC12total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC12).toUpperCase())
}, [galerias])


// C13
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC13 = "C13";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC13(filteredResults);
    setPessoasSearchMapC13total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC13).toUpperCase())
}, [galerias])

// C14
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC14 = "C14";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC14(filteredResults);
    setPessoasSearchMapC14total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC14).toUpperCase())
}, [galerias])

// C15
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC15 = "C15";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC15(filteredResults);
    setPessoasSearchMapC15total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC15).toUpperCase())
}, [galerias])

// C16
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC16 = "C16";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC16(filteredResults);
    setPessoasSearchMapC16total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC16).toUpperCase())
}, [galerias])


// C17
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC17 = "C17";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC17(filteredResults);
    setPessoasSearchMapC17total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC17).toUpperCase())
}, [galerias])

// C18
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC18 = "C18";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC18(filteredResults);
    setPessoasSearchMapC18total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC18).toUpperCase())
}, [galerias])

// C19
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC19 = "C19";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC19(filteredResults);
    setPessoasSearchMapC19total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC19).toUpperCase())
}, [galerias])

// C20
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaC20 = "C20";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapC20(filteredResults);
    setPessoasSearchMapC20total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaC20).toUpperCase())
}, [galerias])


// GALERIA F 

//C C01
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE01 = "E01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE01(filteredResults);
    setPessoasSearchMapE01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE01).toUpperCase())
}, [galerias])

// E02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE02 = "E02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE02(filteredResults);
    setPessoasSearchMapE02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE02).toUpperCase())
}, [galerias])

// E03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE03 = "E03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE03(filteredResults);
    setPessoasSearchMapE03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE03).toUpperCase())
}, [galerias])

// E04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE04 = "E04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE04(filteredResults);
    setPessoasSearchMapE04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE04).toUpperCase())
}, [galerias])
// E05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE05 = "E05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE05(filteredResults);
    setPessoasSearchMapE05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE05).toUpperCase())
}, [galerias])

// E06
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE06 = "E06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE06(filteredResults);
    setPessoasSearchMapE06total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE06).toUpperCase())
}, [galerias])

// E07
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE07 = "E07";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE07(filteredResults);
    setPessoasSearchMapE07total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE07).toUpperCase())
}, [galerias])


// E08
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE08 = "E08";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE08(filteredResults);
    setPessoasSearchMapE08total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE08).toUpperCase())
}, [galerias])
// E09
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE09 = "E09";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE09(filteredResults);
    setPessoasSearchMapE09total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE09).toUpperCase())
}, [galerias])

// C10
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE10 = "E10";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE10(filteredResults);
    setPessoasSearchMapE10total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE10).toUpperCase())
}, [galerias])
// E11
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE11 = "E11";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE11(filteredResults);
    setPessoasSearchMapE11total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE11).toUpperCase())
}, [galerias])

// E12
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE12 = "E12";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE12(filteredResults);
    setPessoasSearchMapE12total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE12).toUpperCase())
}, [galerias])


// E13
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE13 = "E13";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE13(filteredResults);
    setPessoasSearchMapE13total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE13).toUpperCase())
}, [galerias])

// E14
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE14 = "E14";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE14(filteredResults);
    setPessoasSearchMapE14total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE14).toUpperCase())
}, [galerias])

// E15
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE15 = "E15";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE15(filteredResults);
    setPessoasSearchMapE15total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE15).toUpperCase())
}, [galerias])

// E16
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE16 = "E16";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE16(filteredResults);
    setPessoasSearchMapE16total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE16).toUpperCase())
}, [galerias])


// E17
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE17 = "E17";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE17(filteredResults);
    setPessoasSearchMapE17total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE17).toUpperCase())
}, [galerias])

// E18
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE18 = "E18";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE18(filteredResults);
    setPessoasSearchMapE18total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE18).toUpperCase())
}, [galerias])

// E19
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE19 = "E19";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE19(filteredResults);
    setPessoasSearchMapE19total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE19).toUpperCase())
}, [galerias])

// C20
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaE20 = "E20";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapE20(filteredResults);
    setPessoasSearchMapE20total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaE20).toUpperCase())
}, [galerias])

// GALERIA F

useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF01 = "F01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF01(filteredResults);
    setPessoasSearchMapF01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF01).toUpperCase())
}, [galerias])

// F02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF02 = "F02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF02(filteredResults);
    setPessoasSearchMapF02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF02).toUpperCase())
}, [galerias])

// F03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF03 = "F03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF03(filteredResults);
    setPessoasSearchMapF03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF03).toUpperCase())
}, [galerias])

// F04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF04 = "F04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF04(filteredResults);
    setPessoasSearchMapF04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF04).toUpperCase())
}, [galerias])
// F05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF05 = "F05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF05(filteredResults);
    setPessoasSearchMapF05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF05).toUpperCase())
}, [galerias])

// F06
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF06 = "F06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF06(filteredResults);
    setPessoasSearchMapF06total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF06).toUpperCase())
}, [galerias])

// F07
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF07 = "F07";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF07(filteredResults);
    setPessoasSearchMapF07total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF07).toUpperCase())
}, [galerias])


// F08
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF08 = "F08";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF08(filteredResults);
    setPessoasSearchMapF08total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF08).toUpperCase())
}, [galerias])
// F09
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF09 = "F09";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF09(filteredResults);
    setPessoasSearchMapF09total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF09).toUpperCase())
}, [galerias])

// C10
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF10 = "F10";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF10(filteredResults);
    setPessoasSearchMapF10total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF10).toUpperCase())
}, [galerias])
// F11
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF11 = "F11";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF11(filteredResults);
    setPessoasSearchMapF11total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF11).toUpperCase())
}, [galerias])

// F12
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF12 = "F12";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF12(filteredResults);
    setPessoasSearchMapF12total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF12).toUpperCase())
}, [galerias])


// F13
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF13 = "F13";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF13(filteredResults);
    setPessoasSearchMapF13total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF13).toUpperCase())
}, [galerias])

// F14
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF14 = "F14";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF14(filteredResults);
    setPessoasSearchMapF14total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF14).toUpperCase())
}, [galerias])

// F15
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF15 = "F15";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF15(filteredResults);
    setPessoasSearchMapF15total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF15).toUpperCase())
}, [galerias])

// F16
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF16 = "F16";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF16(filteredResults);
    setPessoasSearchMapF16total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF16).toUpperCase())
}, [galerias])


// F17
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF17 = "F17";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF17(filteredResults);
    setPessoasSearchMapF17total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF17).toUpperCase())
}, [galerias])

// F18
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF18 = "F18";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF18(filteredResults);
    setPessoasSearchMapF18total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF18).toUpperCase())
}, [galerias])

// F19
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF19 = "F19";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF19(filteredResults);
    setPessoasSearchMapF19total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF19).toUpperCase())
}, [galerias])

// C20
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaF20 = "F20";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapF20(filteredResults);
    setPessoasSearchMapF20total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaF20).toUpperCase())
}, [galerias])


// GALERIA "G"

//C C01
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG01 = "G01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG01(filteredResults);
    setPessoasSearchMapG01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG01).toUpperCase())
}, [galerias])

// G02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG02 = "G02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG02(filteredResults);
    setPessoasSearchMapG02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG02).toUpperCase())
}, [galerias])

// G03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG03 = "G03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG03(filteredResults);
    setPessoasSearchMapG03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG03).toUpperCase())
}, [galerias])

// G04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG04 = "G04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG04(filteredResults);
    setPessoasSearchMapG04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG04).toUpperCase())
}, [galerias])
// G05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG05 = "G05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG05(filteredResults);
    setPessoasSearchMapG05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG05).toUpperCase())
}, [galerias])

// G06
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG06 = "G06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG06(filteredResults);
    setPessoasSearchMapG06total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG06).toUpperCase())
}, [galerias])

// G07
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG07 = "G07";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG07(filteredResults);
    setPessoasSearchMapG07total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG07).toUpperCase())
}, [galerias])


// G08
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG08 = "G08";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG08(filteredResults);
    setPessoasSearchMapG08total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG08).toUpperCase())
}, [galerias])
// G09
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG09 = "G09";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG09(filteredResults);
    setPessoasSearchMapG09total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG09).toUpperCase())
}, [galerias])

// C10
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG10 = "G10";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG10(filteredResults);
    setPessoasSearchMapG10total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG10).toUpperCase())
}, [galerias])
// G11
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG11 = "G11";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG11(filteredResults);
    setPessoasSearchMapG11total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG11).toUpperCase())
}, [galerias])

// G12
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG12 = "G12";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG12(filteredResults);
    setPessoasSearchMapG12total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG12).toUpperCase())
}, [galerias])


// G13
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG13 = "G13";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG13(filteredResults);
    setPessoasSearchMapG13total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG13).toUpperCase())
}, [galerias])

// G14
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG14 = "G14";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG14(filteredResults);
    setPessoasSearchMapG14total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG14).toUpperCase())
}, [galerias])

// G15
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG15 = "G15";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG15(filteredResults);
    setPessoasSearchMapG15total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG15).toUpperCase())
}, [galerias])

// G16
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG16 = "G16";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG16(filteredResults);
    setPessoasSearchMapG16total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG16).toUpperCase())
}, [galerias])


// G17
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG17 = "G17";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG17(filteredResults);
    setPessoasSearchMapG17total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG17).toUpperCase())
}, [galerias])

// G18
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG18 = "G18";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG18(filteredResults);
    setPessoasSearchMapG18total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG18).toUpperCase())
}, [galerias])

// G19
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG19 = "G19";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG19(filteredResults);
    setPessoasSearchMapG19total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG19).toUpperCase())
}, [galerias])

// C20
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaG20 = "G20";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapG20(filteredResults);
    setPessoasSearchMapG20total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUG IRC GFGTUCR C PGSQUISC
  performSearch((celaG20).toUpperCase())
}, [galerias])

// galeria H

// D201
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHD01 = "HD01";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHD01(filteredResults);
  setPessoasSearchMapHD01total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHD01).toUpperCase())
}, [galerias])

// HD02
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHD02 = "HD02";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHD02(filteredResults);
  setPessoasSearchMapHD02total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHD02).toUpperCase())
}, [galerias])

// HD03
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHD03 = "HD03";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHD03(filteredResults);
  setPessoasSearchMapHD03total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHD03).toUpperCase())
}, [galerias])

// HD04
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHD04 = "HD04";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHD04(filteredResults);
  setPessoasSearchMapHD04total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHD04).toUpperCase())
}, [galerias])
// HD05
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHD05 = "HD05";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHD05(filteredResults);
  setPessoasSearchMapHD05total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHD05).toUpperCase())
}, [galerias])

// D201
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHE01 = "HEX01";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHE01(filteredResults);
  setPessoasSearchMapHE01total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHE01).toUpperCase())
}, [galerias])

// HE02
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHE02 = "HEX02";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHE02(filteredResults);
  setPessoasSearchMapHE02total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHE02).toUpperCase())
}, [galerias])

// HE03
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHE03 = "HEX03";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHE03(filteredResults);
  setPessoasSearchMapHE03total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHE03).toUpperCase())
}, [galerias])

// HE04
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHE04 = "HEX04";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHE04(filteredResults);
  setPessoasSearchMapHE04total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHE04).toUpperCase())
}, [galerias])
// HE05
useEffect(() => {
let array = [];
for (let key in galerias)
  array.push(galerias[key]);
let celaHE05 = "HEX05";

function performSearch(searchTerm) {
  let data = array

  let filteredResults = data.filter(item =>
    item.cela.includes(searchTerm)
  );

  setPessoasSearchMapHE05(filteredResults);
  setPessoasSearchMapHE05total(filteredResults.length)

}
//CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
performSearch((celaHE05).toUpperCase())
}, [galerias])





// D201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDD01 = "DD01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDD01(filteredResults);
    setPessoasSearchMapDD01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDD01).toUpperCase())
}, [galerias])

// DD02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDD02 = "DD02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDD02(filteredResults);
    setPessoasSearchMapDD02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDD02).toUpperCase())
}, [galerias])

// DD03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDD03 = "DD03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDD03(filteredResults);
    setPessoasSearchMapDD03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDD03).toUpperCase())
}, [galerias])

// DD04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDD04 = "DD04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDD04(filteredResults);
    setPessoasSearchMapDD04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDD04).toUpperCase())
}, [galerias])
// DD05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDD05 = "DD05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDD05(filteredResults);
    setPessoasSearchMapDD05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDD05).toUpperCase())
}, [galerias])

// D201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDE01 = "DEX01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDE01(filteredResults);
    setPessoasSearchMapDE01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDE01).toUpperCase())
}, [galerias])

// DE02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDE02 = "DEX02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDE02(filteredResults);
    setPessoasSearchMapDE02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDE02).toUpperCase())
}, [galerias])

// DE03
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDE03 = "DEX03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDE03(filteredResults);
    setPessoasSearchMapDE03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDE03).toUpperCase())
}, [galerias])

// DE04
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDE04 = "DEX04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDE04(filteredResults);
    setPessoasSearchMapDE04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDE04).toUpperCase())
}, [galerias])
// DE05
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaDE05 = "DEX05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapDE05(filteredResults);
    setPessoasSearchMapDE05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaDE05).toUpperCase())
}, [galerias])


// TRIAGEM 
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR = "TR00";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR(filteredResults);
    setPessoasSearchMapTRtotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR).toUpperCase())
}, [galerias])

// TR201
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR01 = "TR01";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR01(filteredResults);
    setPessoasSearchMapTR01total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR01).toUpperCase())
}, [galerias])


// TR02
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR02 = "TR02";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR02(filteredResults);
    setPessoasSearchMapTR02total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR02).toUpperCase())
}, [galerias])



useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR03 = "TR03";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR03(filteredResults);
    setPessoasSearchMapTR03total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR03).toUpperCase())
}, [galerias])

useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR04 = "TR04";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR04(filteredResults);
    setPessoasSearchMapTR04total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR04).toUpperCase())
}, [galerias])

useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR05 = "TR05";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR05(filteredResults);
    setPessoasSearchMapTR05total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR05).toUpperCase())
}, [galerias])

useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let celaTR06 = "TR06";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapTR06(filteredResults);
    setPessoasSearchMapTR06total(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((celaTR06).toUpperCase())
}, [galerias])


useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let alojamento = "ALOJAMENTO";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasSearchMapALOJAMENTO(filteredResults);
    setPessoasSearchMapALOJAMENTOtotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((alojamento).toUpperCase())
}, [galerias])



// DOMICILIAR
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let domiciliar = "DOMICILIAR";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasDomiciliar(filteredResults);
    setPessoasDomiciliartotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((domiciliar).toUpperCase())
}, [galerias])


// HOSPITAL
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let hospital = "HOSPITAL";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasHospital(filteredResults);
    setPessoasHospitaltotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((hospital).toUpperCase())
}, [galerias])


// HOSPITAL
useEffect(() => {
  let array = [];
  for (let key in galerias)
    array.push(galerias[key]);
  let pernoite = "PERNOITE";

  function performSearch(searchTerm) {
    let data = array

    let filteredResults = data.filter(item =>
      item.cela.includes(searchTerm)
    );

    setPessoasPernoite(filteredResults);
    setPessoasPernoitetotal(filteredResults.length)

  }
  //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
  performSearch((pernoite).toUpperCase())
}, [galerias])






  return (




    <>
      <div className={styles.listaGalerias}>




        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaCapa}>
          <b style={ativaBotao === 6 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>CAPA</b></b>
        </div>
        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaA}>
          <b style={ativaBotao === 1 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (A)</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaB}>
          <b style={ativaBotao === 2 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (B)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaC}>
          <b style={ativaBotao === 3 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (C)</b></b>
        </div>
       
       
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaE}>
          <b style={ativaBotao === 8 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (E)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaF}>
          <b style={ativaBotao === 9 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (F)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaG}>
          <b style={ativaBotao === 10 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (G)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaTR}>
          <b style={ativaBotao === 11 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>TRIAGEM (TR)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaD}>
          <b style={ativaBotao === 4 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (D - H - ALOJAMENTO)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGeral}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>TODAS GALERIAS</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer', marginLeft:10,  }} onClick={exportToExcel}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><img src={excel} alt="Logo" height={40} /></b>
        </div>

        {/* Continue com os outros elementos... */}
      </div>

      {(pagina === 6) && <div id='capa'>

        <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CAPA</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
        </Button></center></p>
        <center><div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao" style={{
          border: '1px solid black',
          width: '210mm',
          height: '300mm',
          margin: '0 auto',
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: 'center',
          justifyItems: 'center'



        }}>
          <br></br>
          <center> 

            <p><h2><img src={logo} alt="Logo" height={48} /><b> POLICIA PENAL | ES</b> </h2></p>
           <p>PENITENCIÁRIA DE SEGURANÇA MÁXIMA 1 (PSMA1) <small><b>  
           - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small></p> 
          
            
           
          
          </center>
          <Table bordered style={{ width: '40%', fontSize:12, alignItems:'center', textAlign:'center' }}>
            <thead>
              
            </thead>
            <tbody>

              <tr>
                <td>GALERIA "A"</td>
                <td>{totalSomaA} </td>
              </tr>
              <tr>
                <td>GALERIA "B"</td>
                <td>{totalSomaB} </td>
              </tr>
              <tr>
                <td>GALERIA "C"</td>
                <td>{totalSomaC} </td>
              </tr>
              <tr>
                <td>GALERIA "D"</td>
                <td>{totalSomaD} </td>
              </tr>
              <tr>
                <td>GALERIA "E"</td>
                <td>{totalSomaE} </td>
              </tr>
              <tr>
                <td>GALERIA "F"</td>
                <td>{totalSomaF} </td>
              </tr>
              <tr>
                <td>GALERIA "G"</td>
                <td>{totalSomaG} </td>
              </tr>
              <tr>
                <td>GALERIA "H"</td>
                <td>{totalSomaH} </td>
              </tr>
              <tr>
                <td>ALOJAMENTO</td>
                <td>{pessoasSearchMapALOJAMENTOtotal} </td>
              </tr>
              <tr>
                <td>TRIAGEM</td>
                <td>{totalSomaTR} </td>
              </tr>
              <tr>
                <td> <b>TOTAL NA UP</b></td>
                <td><strong>{totalUp}</strong> </td>
              </tr>


              <tr>
                <td> DOMICILIAR</td>
                <td>{pessoasDomiciliartotal} </td>
              </tr>
              <tr>
                <td>HOSPITAL</td>
                <td>{pessoasHospitaltotal}</td>
              </tr>
              <tr>
                <td>PERNOITE</td>
                <td>{pessoasPernoitetotal}</td>
              </tr>
              <tr>
                <td> <b>TOTAL GERAL</b></td>
                <td><strong>{totalGeral}</strong></td>
              </tr>


            </tbody>
          </Table>
          <table style={{ width: '90%', borderCollapse: 'collapse', border: '1px solid #ddd', fontSize: '10px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">A</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">B</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">C</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">E</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">F</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">G</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">D,H</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">TR</th>

              </tr>
            </thead>

            <tbody>

              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DD01</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDD01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR00</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTRtotal}</td>
                
              </tr>
               <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DD02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDD02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR01</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR01total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DD03</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDD03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR02total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DD04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDD04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR03</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR03total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DD05</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDD05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR04total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DE01</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDE01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR05</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR05total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DE02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDE02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR06</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR06total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DE03</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDE03total}</td>
              
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DE04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDE04total}</td>
          
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>DE05</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapDE05total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HD01</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHD01total}</td>
               
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HD02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHD02total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HD03</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHD03total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HD04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHD04total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HD05</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHD05total}</td>
            
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HE01</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHE01total}</td>
               
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HE02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHE02total}</td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A18</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA18total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B18</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB18total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C18</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC18total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E18</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE18total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F18</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF18total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G18</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG18total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HE03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHE03total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A19</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA19total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B19</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB19total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C19</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC19total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E19</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE19total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F19</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF19total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G19</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG19total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HE04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHE04total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A20</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA20total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B20</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB20total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C20</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC20total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E20</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE20total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>F20</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapF20total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>G20</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapG20total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>HE05</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapHE05total}</td>
              
              </tr>
          
             
             
            


            </tbody>
          </table>

        </div>

        </center>
      </div>}


       {(pagina === 1) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>GALERIA "A"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}
      {(pagina === 5) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CONTAGEM GERAL</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}
      {(pagina === 1 || pagina === 5) && <div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao">

      

        <center> <small style={{ marginTop: -15, }}><b>GALERIA "A" / TOTAL GALERIA ({totalSomaA}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A01" celaTotal={pessoasSearchMapA01total} celaMap={(pessoasSearchMapA01 && pessoasSearchMapA01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A02" celaTotal={pessoasSearchMapA02total} celaMap={(pessoasSearchMapA02 && pessoasSearchMapA02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A03" celaTotal={pessoasSearchMapA03total} celaMap={(pessoasSearchMapA03 && pessoasSearchMapA03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A04" celaTotal={pessoasSearchMapA04total} celaMap={(pessoasSearchMapA04 && pessoasSearchMapA04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A05" celaTotal={pessoasSearchMapA05total} celaMap={(pessoasSearchMapA05 && pessoasSearchMapA05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A06" celaTotal={pessoasSearchMapA06total} celaMap={(pessoasSearchMapA06 && pessoasSearchMapA06)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A07 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A07" celaTotal={pessoasSearchMapA07total} celaMap={(pessoasSearchMapA07 && pessoasSearchMapA07)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A08 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A08" celaTotal={pessoasSearchMapA08total} celaMap={(pessoasSearchMapA08 && pessoasSearchMapA08)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A09 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A09" celaTotal={pessoasSearchMapA09total} celaMap={(pessoasSearchMapA09 && pessoasSearchMapA09)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A10 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A10" celaTotal={pessoasSearchMapA10total} celaMap={(pessoasSearchMapA10 && pessoasSearchMapA10)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A11 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A11" celaTotal={pessoasSearchMapA11total} celaMap={(pessoasSearchMapA11 && pessoasSearchMapA11)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A12 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A12" celaTotal={pessoasSearchMapA12total} celaMap={(pessoasSearchMapA12 && pessoasSearchMapA12)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A13 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A13" celaTotal={pessoasSearchMapA13total} celaMap={(pessoasSearchMapA13 && pessoasSearchMapA13)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A14 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A14" celaTotal={pessoasSearchMapA14total} celaMap={(pessoasSearchMapA14 && pessoasSearchMapA14)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A15 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A15" celaTotal={pessoasSearchMapA15total} celaMap={(pessoasSearchMapA15 && pessoasSearchMapA15)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A16 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A16" celaTotal={pessoasSearchMapA16total} celaMap={(pessoasSearchMapA16 && pessoasSearchMapA16)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A17 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A17" celaTotal={pessoasSearchMapA17total} celaMap={(pessoasSearchMapA17 && pessoasSearchMapA17)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A18 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A18" celaTotal={pessoasSearchMapA18total} celaMap={(pessoasSearchMapA18 && pessoasSearchMapA18)} />
            </Col>
          </Row>
          <Row  >
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A19 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A19" celaTotal={pessoasSearchMapA19total} celaMap={(pessoasSearchMapA19 && pessoasSearchMapA19)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A20 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A20" celaTotal={pessoasSearchMapA20total} celaMap={(pessoasSearchMapA20 && pessoasSearchMapA20)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
             
              <p> <small>TOTAL "A" {totalSomaA} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 5) && <div style={{height:"29mm"}}></div>}
     
      {(pagina === 2) && <p className={styles.pulolista}><center><h1 style={pagina === 2 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "B"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 2 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 2 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">


        <center> <small style={{ marginTop: -10 }}><b>GALERIA "B" / TOTAL GALERIA ({totalSomaB}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B01" celaTotal={pessoasSearchMapB01total} celaMap={(pessoasSearchMapB01 && pessoasSearchMapB01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B02" celaTotal={pessoasSearchMapB02total} celaMap={(pessoasSearchMapB02 && pessoasSearchMapB02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B03" celaTotal={pessoasSearchMapB03total} celaMap={(pessoasSearchMapB03 && pessoasSearchMapB03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B04" celaTotal={pessoasSearchMapB04total} celaMap={(pessoasSearchMapB04 && pessoasSearchMapB04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B05" celaTotal={pessoasSearchMapB05total} celaMap={(pessoasSearchMapB05 && pessoasSearchMapB05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B06" celaTotal={pessoasSearchMapB06total} celaMap={(pessoasSearchMapB06 && pessoasSearchMapB06)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B07 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B07" celaTotal={pessoasSearchMapB07total} celaMap={(pessoasSearchMapB07 && pessoasSearchMapB07)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B08 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B08" celaTotal={pessoasSearchMapB08total} celaMap={(pessoasSearchMapB08 && pessoasSearchMapB08)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B09 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B09" celaTotal={pessoasSearchMapB09total} celaMap={(pessoasSearchMapB09 && pessoasSearchMapB09)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B10 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B10" celaTotal={pessoasSearchMapB10total} celaMap={(pessoasSearchMapB10 && pessoasSearchMapB10)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B11 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B11" celaTotal={pessoasSearchMapB11total} celaMap={(pessoasSearchMapB11 && pessoasSearchMapB11)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B12 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B12" celaTotal={pessoasSearchMapB12total} celaMap={(pessoasSearchMapB12 && pessoasSearchMapB12)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B13 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B13" celaTotal={pessoasSearchMapB13total} celaMap={(pessoasSearchMapB13 && pessoasSearchMapB13)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B14 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B14" celaTotal={pessoasSearchMapB14total} celaMap={(pessoasSearchMapB14 && pessoasSearchMapB14)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B15 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B15" celaTotal={pessoasSearchMapB15total} celaMap={(pessoasSearchMapB15 && pessoasSearchMapB15)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B16 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B16" celaTotal={pessoasSearchMapB16total} celaMap={(pessoasSearchMapB16 && pessoasSearchMapB16)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B17 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B17" celaTotal={pessoasSearchMapB17total} celaMap={(pessoasSearchMapB17 && pessoasSearchMapB17)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B18 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B18" celaTotal={pessoasSearchMapB18total} celaMap={(pessoasSearchMapB18 && pessoasSearchMapB18)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B19 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B19" celaTotal={pessoasSearchMapB19total} celaMap={(pessoasSearchMapB19 && pessoasSearchMapB19)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B20 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B20" celaTotal={pessoasSearchMapB20total} celaMap={(pessoasSearchMapB20 && pessoasSearchMapB20)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              
              <p> <small>TOTAL "B" {totalSomaB} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}

      {(pagina === 5) && <div style={{height:"30mm"}}></div>}
      {(pagina === 3) && <p className={styles.pulolista}><center><h1 style={pagina === 3 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "C"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 3 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 3 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "C" / TOTAL GALERIA ({totalSomaC}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C01" celaTotal={pessoasSearchMapC01total} celaMap={(pessoasSearchMapC01 && pessoasSearchMapC01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C02" celaTotal={pessoasSearchMapC02total} celaMap={(pessoasSearchMapC02 && pessoasSearchMapC02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C03" celaTotal={pessoasSearchMapC03total} celaMap={(pessoasSearchMapC03 && pessoasSearchMapC03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C04" celaTotal={pessoasSearchMapC04total} celaMap={(pessoasSearchMapC04 && pessoasSearchMapC04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C05" celaTotal={pessoasSearchMapC05total} celaMap={(pessoasSearchMapC05 && pessoasSearchMapC05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C06" celaTotal={pessoasSearchMapC06total} celaMap={(pessoasSearchMapC06 && pessoasSearchMapC06)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C07 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C07" celaTotal={pessoasSearchMapC07total} celaMap={(pessoasSearchMapC07 && pessoasSearchMapC07)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C08 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C08" celaTotal={pessoasSearchMapC08total} celaMap={(pessoasSearchMapC08 && pessoasSearchMapC08)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C09 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C09" celaTotal={pessoasSearchMapC09total} celaMap={(pessoasSearchMapC09 && pessoasSearchMapC09)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C10 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C10" celaTotal={pessoasSearchMapC10total} celaMap={(pessoasSearchMapC10 && pessoasSearchMapC10)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C11 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C11" celaTotal={pessoasSearchMapC11total} celaMap={(pessoasSearchMapC11 && pessoasSearchMapC11)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C12 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C12" celaTotal={pessoasSearchMapC12total} celaMap={(pessoasSearchMapC12 && pessoasSearchMapC12)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C13 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C13" celaTotal={pessoasSearchMapC13total} celaMap={(pessoasSearchMapC13 && pessoasSearchMapC13)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C14 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C14" celaTotal={pessoasSearchMapC14total} celaMap={(pessoasSearchMapC14 && pessoasSearchMapC14)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C15 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C15" celaTotal={pessoasSearchMapC15total} celaMap={(pessoasSearchMapC15 && pessoasSearchMapC15)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C16 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C16" celaTotal={pessoasSearchMapC16total} celaMap={(pessoasSearchMapC16 && pessoasSearchMapC16)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C17 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C17" celaTotal={pessoasSearchMapC17total} celaMap={(pessoasSearchMapC17 && pessoasSearchMapC17)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C18 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C18" celaTotal={pessoasSearchMapC18total} celaMap={(pessoasSearchMapC18 && pessoasSearchMapC18)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C19 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C19" celaTotal={pessoasSearchMapC19total} celaMap={(pessoasSearchMapC19 && pessoasSearchMapC19)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C20 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C20" celaTotal={pessoasSearchMapC20total} celaMap={(pessoasSearchMapC20 && pessoasSearchMapC20)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              
              <p> <small>TOTAL "C" {totalSomaC} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 5) && <div style={{height:"30mm"}}></div>}
      
      {(pagina === 4) && <p className={styles.pulolista}><center><h1 style={pagina === 4 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "D , H e ALOJAMENTO"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 4 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 4 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIAS "D , H e ALOJAMENTO - TOTAL GALERIA 'D' ({totalSomaD}) / TOTAL GALERIA 'H' ({totalSomaH}) / TOTAL AOLJAMENTO ({pessoasSearchMapALOJAMENTOtotal}) em {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DD01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DD01" celaTotal={pessoasSearchMapDD01total} celaMap={(pessoasSearchMapDD01 && pessoasSearchMapDD01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DD02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DD02" celaTotal={pessoasSearchMapDD02total} celaMap={(pessoasSearchMapDD02 && pessoasSearchMapDD02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DD03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DD03" celaTotal={pessoasSearchMapDD03total} celaMap={(pessoasSearchMapDD03 && pessoasSearchMapDD03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DD04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DD04" celaTotal={pessoasSearchMapDD04total} celaMap={(pessoasSearchMapDD04 && pessoasSearchMapDD04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DD05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DD05" celaTotal={pessoasSearchMapDD05total} celaMap={(pessoasSearchMapDD05 && pessoasSearchMapDD05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none',zIndex: 10 }}>
           
            <center><b>ALOJAMENTO</b></center>
              <Celacontagem cela="ALOJAMENTO" celaTotal={pessoasSearchMapALOJAMENTOtotal} celaMap={(pessoasSearchMapALOJAMENTO && pessoasSearchMapALOJAMENTO)} />

              </Col>
          </Row>
          <Row>
          <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DE01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DEX01" celaTotal={pessoasSearchMapHD01total} celaMap={(pessoasSearchMapDE01 && pessoasSearchMapDE01)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DE02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="DEX02" celaTotal={pessoasSearchMapDE02total} celaMap={(pessoasSearchMapDE02 && pessoasSearchMapDE02)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DE03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="DEX03" celaTotal={pessoasSearchMapDE03total} celaMap={(pessoasSearchMapDE03 && pessoasSearchMapDE03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DE04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="DEX04" celaTotal={pessoasSearchMapDE04total} celaMap={(pessoasSearchMapDE04 && pessoasSearchMapDE04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>DE05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="DEX05" celaTotal={pessoasSearchMapDE05total} celaMap={(pessoasSearchMapDE05 && pessoasSearchMapDE05)} />

            </Col>
            
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none', borderTop: 'none' }}>
            
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HD01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HD01" celaTotal={pessoasSearchMapHD01total} celaMap={(pessoasSearchMapHD01 && pessoasSearchMapHD01)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HD02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HD02" celaTotal={pessoasSearchMapHD02total} celaMap={(pessoasSearchMapHD02 && pessoasSearchMapHD02)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HD03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HD03" celaTotal={pessoasSearchMapHD03total} celaMap={(pessoasSearchMapHD03 && pessoasSearchMapHD03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HD04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HD04" celaTotal={pessoasSearchMapHD04total} celaMap={(pessoasSearchMapHD04 && pessoasSearchMapHD04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HD05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HD05" celaTotal={pessoasSearchMapHD05total} celaMap={(pessoasSearchMapHD05 && pessoasSearchMapHD05)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none', borderTop: 'none' }}>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HE01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HE01" celaTotal={pessoasSearchMapHE01total} celaMap={(pessoasSearchMapHE01 && pessoasSearchMapHE01)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HE02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HE02" celaTotal={pessoasSearchMapHE02total} celaMap={(pessoasSearchMapHE02 && pessoasSearchMapHE02)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HE03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HE01" celaTotal={pessoasSearchMapHE03total} celaMap={(pessoasSearchMapHE03 && pessoasSearchMapHE03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HE04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HE04" celaTotal={pessoasSearchMapHE04total} celaMap={(pessoasSearchMapHE04 && pessoasSearchMapHE04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>HE05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="HE05" celaTotal={pessoasSearchMapHE05total} celaMap={(pessoasSearchMapHE05 && pessoasSearchMapHE05)} />

              </Col>           
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>
            
             
              </Col>           

          </Row>



        </Container>


      </div>}
      {(pagina === 5) && <div style={{height:"31mm"}}></div>}
      {(pagina === 8) && <p className={styles.pulolista}><center><h1 style={pagina === 8 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "E"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 8 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 8 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "E" / TOTAL GALERIA ({totalSomaE}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E01" celaTotal={pessoasSearchMapE01total} celaMap={(pessoasSearchMapE01 && pessoasSearchMapE01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E02" celaTotal={pessoasSearchMapE02total} celaMap={(pessoasSearchMapE02 && pessoasSearchMapE02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E03" celaTotal={pessoasSearchMapE03total} celaMap={(pessoasSearchMapE03 && pessoasSearchMapE03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E04" celaTotal={pessoasSearchMapE04total} celaMap={(pessoasSearchMapE04 && pessoasSearchMapE04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E05" celaTotal={pessoasSearchMapE05total} celaMap={(pessoasSearchMapE05 && pessoasSearchMapE05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E06" celaTotal={pessoasSearchMapE06total} celaMap={(pessoasSearchMapE06 && pessoasSearchMapE06)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E07 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E07" celaTotal={pessoasSearchMapE07total} celaMap={(pessoasSearchMapE07 && pessoasSearchMapE07)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E08 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E08" celaTotal={pessoasSearchMapE08total} celaMap={(pessoasSearchMapE08 && pessoasSearchMapE08)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E09 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E09" celaTotal={pessoasSearchMapE09total} celaMap={(pessoasSearchMapE09 && pessoasSearchMapE09)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E10 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="E10" celaTotal={pessoasSearchMapE10total} celaMap={(pessoasSearchMapE10 && pessoasSearchMapE10)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E11 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E11" celaTotal={pessoasSearchMapE11total} celaMap={(pessoasSearchMapE11 && pessoasSearchMapE11)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E12 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E12" celaTotal={pessoasSearchMapE12total} celaMap={(pessoasSearchMapE12 && pessoasSearchMapE12)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E13 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E13" celaTotal={pessoasSearchMapE13total} celaMap={(pessoasSearchMapE13 && pessoasSearchMapE13)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E14 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E14" celaTotal={pessoasSearchMapE14total} celaMap={(pessoasSearchMapE14 && pessoasSearchMapE14)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E15 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E15" celaTotal={pessoasSearchMapE15total} celaMap={(pessoasSearchMapE15 && pessoasSearchMapE15)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E16 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E16" celaTotal={pessoasSearchMapE16total} celaMap={(pessoasSearchMapE16 && pessoasSearchMapE16)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E17 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E17" celaTotal={pessoasSearchMapE17total} celaMap={(pessoasSearchMapE17 && pessoasSearchMapE17)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E18 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E18" celaTotal={pessoasSearchMapE18total} celaMap={(pessoasSearchMapE18 && pessoasSearchMapE18)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E19 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E19" celaTotal={pessoasSearchMapE19total} celaMap={(pessoasSearchMapE19 && pessoasSearchMapE19)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E20 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="E20" celaTotal={pessoasSearchMapE20total} celaMap={(pessoasSearchMapE20 && pessoasSearchMapE20)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
             
              <p> <small>TOTAL "E" {totalSomaE} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}

      {(pagina === 5) && <div style={{height:"30mm"}}></div>}
      {(pagina === 9) && <p className={styles.pulolista}><center><h1 style={pagina === 9 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "F"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 9 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 9 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "F" / TOTAL GALERIA ({totalSomaF}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F01" celaTotal={pessoasSearchMapF01total} celaMap={(pessoasSearchMapF01 && pessoasSearchMapF01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F02" celaTotal={pessoasSearchMapF02total} celaMap={(pessoasSearchMapF02 && pessoasSearchMapF02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F03" celaTotal={pessoasSearchMapF03total} celaMap={(pessoasSearchMapF03 && pessoasSearchMapF03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F04" celaTotal={pessoasSearchMapF04total} celaMap={(pessoasSearchMapF04 && pessoasSearchMapF04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F05" celaTotal={pessoasSearchMapF05total} celaMap={(pessoasSearchMapF05 && pessoasSearchMapF05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F06" celaTotal={pessoasSearchMapF06total} celaMap={(pessoasSearchMapF06 && pessoasSearchMapF06)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F07 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F07" celaTotal={pessoasSearchMapF07total} celaMap={(pessoasSearchMapF07 && pessoasSearchMapF07)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F08 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="F08" celaTotal={pessoasSearchMapF08total} celaMap={(pessoasSearchMapF08 && pessoasSearchMapF08)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F09 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="F09" celaTotal={pessoasSearchMapF09total} celaMap={(pessoasSearchMapF09 && pessoasSearchMapF09)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F10 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="F10" celaTotal={pessoasSearchMapF10total} celaMap={(pessoasSearchMapF10 && pessoasSearchMapF10)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F11 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F11" celaTotal={pessoasSearchMapF11total} celaMap={(pessoasSearchMapF11 && pessoasSearchMapF11)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F12 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F12" celaTotal={pessoasSearchMapF12total} celaMap={(pessoasSearchMapF12 && pessoasSearchMapF12)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F13 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F13" celaTotal={pessoasSearchMapF13total} celaMap={(pessoasSearchMapF13 && pessoasSearchMapF13)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F14 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F14" celaTotal={pessoasSearchMapF14total} celaMap={(pessoasSearchMapF14 && pessoasSearchMapF14)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F15 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F15" celaTotal={pessoasSearchMapF15total} celaMap={(pessoasSearchMapF15 && pessoasSearchMapF15)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F16 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F16" celaTotal={pessoasSearchMapF16total} celaMap={(pessoasSearchMapF16 && pessoasSearchMapF16)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F17 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F17" celaTotal={pessoasSearchMapF17total} celaMap={(pessoasSearchMapF17 && pessoasSearchMapF17)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F18 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F18" celaTotal={pessoasSearchMapF18total} celaMap={(pessoasSearchMapF18 && pessoasSearchMapF18)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F19 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F19" celaTotal={pessoasSearchMapF19total} celaMap={(pessoasSearchMapF19 && pessoasSearchMapF19)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F20 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="F20" celaTotal={pessoasSearchMapF20total} celaMap={(pessoasSearchMapF20 && pessoasSearchMapF20)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
             
              <p> <small>TOTAL "F" {totalSomaF} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}

      {(pagina === 5) && <div style={{height:"30mm"}}></div>}
      {(pagina === 10) && <p className={styles.pulolista}><center><h1 style={pagina === 10 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "G"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 10 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 10 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "G" / TOTAL GALERIA ({totalSomaG}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G01" celaTotal={pessoasSearchMapG01total} celaMap={(pessoasSearchMapG01 && pessoasSearchMapG01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G02" celaTotal={pessoasSearchMapG02total} celaMap={(pessoasSearchMapG02 && pessoasSearchMapG02)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G03" celaTotal={pessoasSearchMapG03total} celaMap={(pessoasSearchMapG03 && pessoasSearchMapG03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G04" celaTotal={pessoasSearchMapG04total} celaMap={(pessoasSearchMapG04 && pessoasSearchMapG04)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G05" celaTotal={pessoasSearchMapG05total} celaMap={(pessoasSearchMapG05 && pessoasSearchMapG05)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G06" celaTotal={pessoasSearchMapG06total} celaMap={(pessoasSearchMapG06 && pessoasSearchMapG06)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G07 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G07" celaTotal={pessoasSearchMapG07total} celaMap={(pessoasSearchMapG07 && pessoasSearchMapG07)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G08 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="G08" celaTotal={pessoasSearchMapG08total} celaMap={(pessoasSearchMapG08 && pessoasSearchMapG08)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G09 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="G09" celaTotal={pessoasSearchMapG09total} celaMap={(pessoasSearchMapG09 && pessoasSearchMapG09)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>F10 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="G10" celaTotal={pessoasSearchMapG10total} celaMap={(pessoasSearchMapG10 && pessoasSearchMapG10)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G11 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G11" celaTotal={pessoasSearchMapG11total} celaMap={(pessoasSearchMapG11 && pessoasSearchMapG11)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G12 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G12" celaTotal={pessoasSearchMapG12total} celaMap={(pessoasSearchMapG12 && pessoasSearchMapG12)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G13 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G13" celaTotal={pessoasSearchMapG13total} celaMap={(pessoasSearchMapG13 && pessoasSearchMapG13)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G14 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G14" celaTotal={pessoasSearchMapG14total} celaMap={(pessoasSearchMapG14 && pessoasSearchMapG14)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G15 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G15" celaTotal={pessoasSearchMapG15total} celaMap={(pessoasSearchMapG15 && pessoasSearchMapG15)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G16 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G16" celaTotal={pessoasSearchMapG16total} celaMap={(pessoasSearchMapG16 && pessoasSearchMapG16)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G17 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G17" celaTotal={pessoasSearchMapG17total} celaMap={(pessoasSearchMapG17 && pessoasSearchMapG17)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G18 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G18" celaTotal={pessoasSearchMapG18total} celaMap={(pessoasSearchMapG18 && pessoasSearchMapG18)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G19 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G19" celaTotal={pessoasSearchMapG19total} celaMap={(pessoasSearchMapG19 && pessoasSearchMapG19)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>G20 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="G20" celaTotal={pessoasSearchMapG20total} celaMap={(pessoasSearchMapG20 && pessoasSearchMapG20)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
             
              <p> <small>TOTAL "G" {totalSomaG} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
     
      {(pagina === 5) && <div style={{height:"30mm"}}></div>}
      {(pagina === 11) && <p className={styles.pulolista}><center><h1 style={pagina === 11 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>TRIAGEM</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 11 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 11 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>TRIAGEM / TOTAL GALERIA ({totalSomaTR}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR" celaTotal={pessoasSearchMapTRtotal} celaMap={(pessoasSearchMapTR && pessoasSearchMapTR)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR01 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR01" celaTotal={pessoasSearchMapTR01total} celaMap={(pessoasSearchMapTR01 && pessoasSearchMapTR01)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR02 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR02" celaTotal={pessoasSearchMapTR02total} celaMap={(pessoasSearchMapTR02 && pessoasSearchMapTR02)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR03 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR03" celaTotal={pessoasSearchMapTR03total} celaMap={(pessoasSearchMapTR03 && pessoasSearchMapTR03)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR04 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR04" celaTotal={pessoasSearchMapTR04total} celaMap={(pessoasSearchMapTR04 && pessoasSearchMapTR04)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR05 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR05" celaTotal={pessoasSearchMapTR05total} celaMap={(pessoasSearchMapTR05 && pessoasSearchMapTR05)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR06 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR06" celaTotal={pessoasSearchMapTR06total} celaMap={(pessoasSearchMapTR06 && pessoasSearchMapTR06)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>


            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
             
              <p> <small>TOTAL "TR" {totalSomaTR} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
     
    </>

  )
}

export default Contagem