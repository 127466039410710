import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './App.css';
import axios from 'axios'
// Components
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading'; // Importe o componente de Loading aqui

// Pages
import Home from './pages/home/Home.js';
import Cadastro from './pages/cadastro/Cadastro';
import Movimento from './pages/movimento/Movimento';
import Contagem from './pages/contagem/Contagem';
import Login from './pages/login/Login';
import Localizador from './pages/localizador/Localizador'

import { DataProvider } from './DataContext';

// Função para atualizar os dados do movimento
export async function atualizarDadosMovimento(setGalerias, setLoading) {
  try {
    const response = await axios.get('https://alertadiarioes.com/apipsma1/get_galerias_cdpg.php');
    setGalerias(response.data);
  } catch (error) {
    console.error('Erro ao buscar os dados do movimento:', error);
  }
}



function App() {
  const [userVerificacao, setUserVerificacao] = useState(null);
  const [loading, setLoading] = useState(true); // Adicione um estado para controle de loading
  const [galerias, setGalerias] = useState([])
  const[movimento, setMovimento] = useState([])
  let timeoutId;

  useEffect(() => {

    const fetchMovimento = async () => {
      try {
        const response = await axios.get('https://alertadiarioes.com/apipsma1/get_movimento_cdpg.php');
        setMovimento(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados do movimento:', error);
      }
    };
  
    fetchMovimento();
  }, []);

  async function atualizarDadosMovimento2() {
    try {
      const response = await axios.get('https://alertadiarioes.com/apipsma1/get_galerias_cdpg.php');
      setGalerias(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados do movimento:', error);
     
    }
  }

function resetTimer() {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    window.location.reload();
  }, 15 * 60 * 1000); // 30 minutos em milissegundos
}

function setupPageReloadOnInactive() {
  resetTimer(); // Inicia o temporizador quando a página é carregada

  window.addEventListener('mousemove', resetTimer); // Reinicia o temporizador quando o mouse é movido
  window.addEventListener('keypress', resetTimer); // Reinicia o temporizador quando uma tecla é pressionada
}

// Chama a função de configuração quando a página é carregada
window.addEventListener('load', setupPageReloadOnInactive);


const fetchMovimento = async () => {
  try {
    setLoading(true); // Define loading como true antes de chamar a função
    await atualizarDadosMovimento(setGalerias); // Chama a função para atualizar os dados do movimento
    setLoading(false); // Define loading como false após a conclusão da função
  } catch (error) {
    console.error('Erro ao buscar os dados do movimento:', error);
  }
};


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserVerificacao(user.email); 
        fetchMovimento();     
        setLoading(false); // Quando a verificação terminar, seta loading para falso

      } else {
        setUserVerificacao(null);
         // Quando a verificação terminar, seta loading para falso
         setLoading(false); // Quando a verificação terminar, seta loading para falso

        return <Loading />; // Se estiver carregando, renderiza o componente de loading
        
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Função para atualizar os dados localmente (sem enviar para a API)
  

  if (loading) {
    return <Loading />; // Se estiver carregando, renderiza o componente de loading
  }

  return (
    <div className="App">
       <DataProvider>
        <BrowserRouter>
        {userVerificacao !== null && <Header userVerificacao={userVerificacao}/>}
        {userVerificacao == null && <Login />}
        <Routes>
       
          {userVerificacao !== null && <Route path="/" element={<Home galerias={galerias}  pagina="1" userVerificacao={userVerificacao} />} />}
          {userVerificacao !== null && <Route path="/movimento" element={<Movimento movimento={movimento}/>} />}
          {userVerificacao !== null && <Route path="/contagem" element={<Contagem galerias={galerias} atualizarDadosMovimento2={atualizarDadosMovimento2} />} />}
          {userVerificacao !== null && <Route path="/inclusao" element={<Cadastro />} />}        
          {userVerificacao !== null && <Route path="/localizador" element={<Localizador galerias={galerias} />} />}        
          
        </Routes>
        {userVerificacao !== null && <Footer />}
      </BrowserRouter>
      </DataProvider>
    </div>
  );
}

export default App;
