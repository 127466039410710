// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpS8dPxEEM5ruAkwV_-4w82b8Up9FWA-s",
  authDomain: "pppsma1.firebaseapp.com",
  projectId: "pppsma1",
  storageBucket: "pppsma1.appspot.com",
  messagingSenderId: "944480513920",
  appId: "1:944480513920:web:ab45aa0222c1b2c05d1b65"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



