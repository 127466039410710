// DataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Crie o contexto
const DataContext = createContext();

// Crie um provedor para o contexto
export const DataProvider = ({ children }) => {
  const [datacela, setDataCela] = useState([]);
  const [dataisolamento, setDataIsolamento] = useState([]);
  const [update ,setUpdate] = useState(1)

  useEffect(() => {
    const fetchDataIsolamento = async () => {
      try {
        const response = await fetch('https://alertadiarioes.com/apipsma1/get_isolamento.php'); // Substitua pela URL real da sua API
        const result = await response.json();
        const resultArray = Object.values(result);
        setDataIsolamento(JSON.stringify(resultArray));

      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchDataIsolamento();
  }, [update]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://alertadiarioes.com/apipsma1/get_cela_descricao.php'); // Substitua pela URL real da sua API
        const result2 = await response.json();
        const resultArray2 = Object.values(result2);
        setDataCela(resultArray2);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    fetchData();
  }, [update]);

  function atualizarCela() {
   setUpdate(update++)
  };

  return (
    <DataContext.Provider value={{ datacela, dataisolamento, atualizarCela }} >
      {children}
    </DataContext.Provider>
  );
};

// Custom hook para usar o contexto
export const useData = () => {
  return useContext(DataContext);
};
