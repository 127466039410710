import React from 'react';
import styles from './Loading.module.css'; // Importa o CSS para o componente Loading
import Lottie from 'react-lottie';
import loadingAnimation from './load.json'; // Arquivo JSON da animação
import logo from '../assets/img/logo.png';


    const Loading = () => {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: loadingAnimation,
        };

  return (
    <div className={styles.loading}>
      <img src={logo} alt="Logo" style={{ marginBottom: -40, height:120 }} />
      <Lottie options={defaultOptions} height={150} width={150} />
      <p style={{marginTop:-55, fontSize:15, letterSpacing:0.5, color:'#dedfe0'}}><b>CARREGANDO</b></p>
    </div>
  );
};

export default Loading;
