

const Celacontagem = (props) => {

  const abreviarNome = (nomeCompleto) => {
    // Dividir o nome completo em partes usando espaços como delimitador
    const partesNome = nomeCompleto.split(" ");
  
    // Extrair o primeiro nome (parte inicial do array)
    const primeiroNome = partesNome[0];
  
    // Extrair o último nome (última parte do array)
    const ultimoNome = partesNome[partesNome.length - 1];
  
    // Abreviar os nomes do meio (se houver mais de um nome)
    let nomesMeioAbreviados = "";
    if (partesNome.length > 2) {
      for (let i = 1; i < partesNome.length - 1; i++) {
        nomesMeioAbreviados += partesNome[i].charAt(0) + ". "; // Adiciona a primeira letra do nome do meio seguida de um ponto
      }
    }
  
    // Retornar o nome abreviado
    return `${primeiroNome} ${nomesMeioAbreviados}${ultimoNome}`;
  };

  if (props.celaMap && props.celaMap.length > 0) {
    // Ordenar o array com base em uma chave específica (por exemplo, 'nome')
    props.celaMap.sort((a, b) => {
        // Comparar os valores da chave 'nome' para ordenação alfabética
        return a.nome.localeCompare(b.nome);
    });
    
    // Agora, props.celaMap está ordenado em ordem alfabética com base no valor da chave 'nome'
}

  return (
    <><div >

      {props.celaMap && props.celaMap.map((preso, index) => (

        <tr >
         


          <td style={{fontSize:10, justifyContent:'left'}} ><p style={{marginBottom:-4, letterSpacing:0.1, marginTop:-2.5, marginLeft:-9}}><b> <small>{index + 1}.</small></b> {abreviarNome(preso.nome.substring(0, 21))}</p></td>


        </tr>


      ))}
      {props.celaTotal === 0 && <small style={{ color: "gray", fontSize:12 }}><center><i>CELA VAZIA</i></center></small>}</div>
    </>
  )
}

export default Celacontagem