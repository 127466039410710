import React, { useState } from 'react';
import styles from './Localizador.module.css';

const Localizador = ({ galerias }) => {
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(true); // Estado para controlar a exibição do input de busca

  // Consistently use "galerias" as the data source
  const data = galerias;

  const handleSearch = () => {
    const lines = searchText.split('\n');
    const trimmedLines = lines.map(line => line.trim());
    const filteredLines = trimmedLines.filter(line => line.length >= 4);
    const results = filteredLines.map(line => {
      return data.find(item => item.nome.toLowerCase() === line.toLowerCase());
    });
    setSearchResult(results);
    setShowSearchInput(false); // Oculta a div de entrada de texto após a pesquisa
  };

  const handleClearSearch = () => {
    setSearchText(''); // Limpa o texto de busca
    setSearchResult([]); // Limpa os resultados de busca
    setShowSearchInput(true); // Mostra a div de entrada de texto novamente
  };

  const formatSearchResult = () => {
    return searchResult.map((result, index) => {
      if (result) {
        return (
          <div key={result.nome}>
            <th>
              <td style={{ border: '1px solid black', width: '550px' }}>
                {result.nome}
              </td>
              <td style={{ border: '1px solid black', width: '300px', textAlign: 'center' }}>
                {result.cela}
              </td>
            </th>
          </div>
        );
      } else {
        // Renderiza o nome que não foi encontrado ao lado da mensagem "Não encontrado"
        return (
          <div key={`notFound-${index}`}>
            <th>
              <td style={{ border: '1px solid black', width: '580px', color:'red' }}>
                {searchText.split('\n')[index]} {/* Exibe o nome que não foi encontrado */}
              </td>
              <td style={{ border: '1px solid black', width: '300px', textAlign: 'center', fontSize:14, color:'red' }}>
                NÃO ENCONTRADO
              </td>
            </th>
          </div>
        );
      }
    });
  };

  return (
    <div className={styles.AppLoc}>
      <h1 style={{fontFamily:'policiapenal', marginTop:60}}>LOCALIZADOR EM MASSA</h1>
      <h5><b>INSERIR UM NOME POR LINHA PARA LOCALIZAÇÃO</b></h5>
      <div style={{ margin: 30, padding: 30, display: 'flex', justifyContent: 'center', gap: 20 }}>
  <button style={{ padding: '10px 20px', backgroundColor: '#545f34', color: 'white', border: 'none', borderRadius: 5, cursor: 'pointer', fontWeight: 'bold' }} onClick={handleSearch}>BUSCAR</button>
  <button style={{ padding: '10px 20px', backgroundColor: 'gray', color: 'white', border: 'none', borderRadius: 5, cursor: 'pointer', fontWeight: 'bold' }} onClick={handleClearSearch}>LIMPAR</button>
</div>

      {/* Renderização condicional para mostrar a div de entrada de texto */}
      {showSearchInput && (
        <div
          contentEditable
          plaintext
          className={styles.searchTextarea}
          onInput={(e) => setSearchText(e.target.innerText)}
          placeholder="Digite os nomes a serem pesquisados, um por linha"
        />
      )}
     {!showSearchInput && ( <div className={styles.searchTextarea}>{formatSearchResult()}</div>)}
    </div>
  );
};

export default Localizador;
